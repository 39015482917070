import React from "react";

import SvgIcon, { type Props } from "@hey-lady/ui/atoms/SvgIcon";

const IconArrowLeft: React.FC<Props> = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="M14 17.8c-.2 0-.4-.1-.5-.2l-5-5c-.3-.3-.3-.8 0-1.1l5-5c.3-.3.8-.3 1.1 0s.3.8 0 1.1L10.1 12l4.5 4.5c.3.3.3.8 0 1.1-.2.1-.4.2-.6.2z" />
    </SvgIcon>
  );
};

export default IconArrowLeft;
