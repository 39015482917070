import {
  FriendJoinedNotificationType,
  MonthlyReportNotificationType,
  type NotificationBaseModel,
  NotificationType,
  ProfileUpdateNotificationType,
} from "@hey-lady/shared/types/graphql";
import MonthlyRecapNotification from "../UserStats/MonthlyRecapNotification";
import BadgeNotificationModal from "./modals/BadgeNotificationModal";
import CompleteYourProfileNotification from "./modals/CompleteYourProfileNotification";
import EventIsStartingModal from "./modals/EventIsStartingModal";
import FriendAcceptedInviteModal from "./modals/FriendAcceptedInviteModal";
import FriendJoinedModal from "./modals/FriendJoinedModal";
import UpdateAvailabilityNotification from "./modals/UpdateAvailabilityNotification";

export interface Props {
  notification: NotificationBaseModel;
}

export default function NotificationModalMatcher({ notification }: Props) {
  if (notification.type === NotificationType.Event && notification.eventNotification) {
    return (
      <EventIsStartingModal
        eventNotification={notification.eventNotification}
        notificationId={notification.id}
      />
    );
  }

  if (notification.type === NotificationType.Badge && notification.badgeNotification) {
    return (
      <BadgeNotificationModal
        badgeNotification={notification.badgeNotification}
        notificationId={notification.id}
      />
    );
  }

  if (notification.type === NotificationType.ProfileUpdate) {
    if (
      notification.profileUpdateNotification?.type === ProfileUpdateNotificationType.CompleteProfile
    ) {
      return <CompleteYourProfileNotification notification={notification} />;
    }

    if (
      notification.profileUpdateNotification?.type ===
      ProfileUpdateNotificationType.UpdateAvailability
    ) {
      return <UpdateAvailabilityNotification notification={notification} />;
    }
  }

  if ((notification.type as string) === MonthlyReportNotificationType.MonthlyReport) {
    return <MonthlyRecapNotification notificationId={notification.id} />;
  }

  if (
    notification.type === NotificationType.FriendJoined &&
    notification.friendJoinedNotification &&
    notification.friendJoinedNotification.type === FriendJoinedNotificationType.AcceptedInvite
  ) {
    return (
      <FriendAcceptedInviteModal
        notificationId={notification.id}
        notification={notification.friendJoinedNotification}
      />
    );
  }

  if (
    notification.type === NotificationType.FriendJoined &&
    notification.friendJoinedNotification &&
    notification.friendJoinedNotification.type === FriendJoinedNotificationType.FriendJoined
  ) {
    return (
      <FriendJoinedModal
        notificationId={notification.id}
        notification={notification.friendJoinedNotification}
      />
    );
  }
}
