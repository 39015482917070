import { Box, type Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import {
  MonthlyRecapSteps,
  useMonthlyRecapStepsQuery,
  useSeenMonthlyReportQuery,
} from "@hey-lady/shared/types/graphql";

import { getLastMonthDates } from "@hey-lady/shared/helpers/date-time";
import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages, useSwitch } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";

import Button from "@hey-lady/ui/atoms/Button";
import Modal from "@hey-lady/ui/atoms/Modal";
import Typography from "@hey-lady/ui/atoms/Typography";
import Group from "@hey-lady/ui/molecules/Group";

import image from "@hey-lady/shared/static/images/statistics-illustration.png";

import { useNotifications } from "../../context/NotificationsContext";

/**
 * Types
 */
export interface Props {
  notificationId: string;
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: "500px",
    marginTop: theme.spacing(Spacing.xxl),
    paddingTop: theme.spacing(Spacing.l),
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
    },
  },
  image: {
    width: "240px",
    height: "auto",
  },
}));

export default function MonthlyRecapNotification({ notificationId }: Props) {
  const { classes } = useStyles();

  const { me } = useAuth();
  const [isOpen, close] = useSwitch(true);
  const { markAsSeen } = useNotifications();
  const { data, loading } = useSeenMonthlyReportQuery();
  const seenReport = data?.seenMonthlyReport ?? false;

  const closeModal = () => {
    markAsSeen(notificationId);
    close();
  };

  const { startDate, endDate } = getLastMonthDates(new Date(), 1);

  const { data: monthlyRecapStepsData, loading: loadingSteps } = useMonthlyRecapStepsQuery({
    fetchPolicy: "cache-first",
    variables: {
      input: {
        userId: me?.id,
        start: startDate,
        end: endDate,
      },
    },
    skip: !me?.id,
    onCompleted: () => {
      const activeSteps = monthlyRecapStepsData?.monthlyRecapSteps ?? [];
      const hasAttendedOrHostedEvent =
        activeSteps.includes(MonthlyRecapSteps.EventsAttended) ||
        activeSteps.includes(MonthlyRecapSteps.EventsHosted);
      if (seenReport || !hasAttendedOrHostedEvent) {
        closeModal();
      }
    },
  });

  const [title, description, seeStatistics, checkProfile] = useFormatMessages([
    { id: "ACCOUNT_PAGE_MONTHLY_STATISTICS_READY", values: { name: me?.firstName } },
    { id: "ACCOUNT_PAGE_MONTHLY_CHECK_PROGRESS" },
    { id: "ACCOUNT_PAGE_SEE_STATISTICS" },
    { id: "ACCOUNT_PAGE_CHECK_PROFILE" },
  ]);

  if (loading || loadingSteps) return null;

  return (
    <Modal open={isOpen} onClose={closeModal} fullSize>
      <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
        <img src={image} className={classes.image} alt="illustration" />

        <Box mt={Spacing.m}>
          <Typography variant="h4" align="center" weight={FontWeight.Semibold}>
            {title}
          </Typography>
        </Box>
        <Box mt={Spacing.s} mb={Spacing.xl}>
          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Box>

        <Group spacing={Spacing.m} justify="center">
          <Link to="/account/profile">
            <Button variant="outlined" color="primary" onClick={closeModal}>
              {checkProfile}
            </Button>
          </Link>
          <Link to="/review/1">
            <Button variant="contained" color="primary" onClick={closeModal}>
              {seeStatistics}
            </Button>
          </Link>
        </Group>
      </Box>
    </Modal>
  );
}
