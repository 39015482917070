import { type AvatarProps, Avatar as MuiAvatar, type Theme } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import config from "../overrides/config";

/**
 * Types
 */
interface Props extends AvatarProps {
  border?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  variant?: "circular" | "square";
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  sizeXs: {
    width: "32px",
    height: "32px",
  },
  sizeSm: {
    width: "44px",
    height: "44px",
  },
  sizeMd: {
    width: "48px",
    height: "48px",
  },
  sizeLg: {
    width: "104px",
    height: "104px",
  },
  sizeXl: {
    width: "112px",
    height: "112px",
  },
  border: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.white,
  },
  circle: {
    borderRadius: config.radius.l,
  },
}));

const Avatar: React.FC<Props> = ({
  size = "sm",
  variant = "square",
  border = false,
  ...props
}: Props) => {
  const { classes, cx } = useStyles();

  return (
    <MuiAvatar
      {...props}
      variant="rounded"
      className={cx({
        [classes.border]: border,
        [classes.circle]: variant === "circular",
        [classes.sizeXs]: size === "xs",
        [classes.sizeSm]: size === "sm",
        [classes.sizeMd]: size === "md",
        [classes.sizeLg]: size === "lg",
        [classes.sizeXl]: size === "xl",
        [props.className ?? ""]: true,
      })}
    />
  );
};

export default Avatar;
