import React, { type ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import Box, { type BoxProps } from "@mui/material/Box";

import { Spacing } from "@hey-lady/shared/helpers/enum";

/**
 * Types
 */
interface Props extends BoxProps {
  wrap?: boolean;
  spacing?: Spacing;
  fullWidth?: boolean;
  justify?: "flex-start" | "center" | "space-between" | "space-around";
  children?: ReactNode;
}

/**
 * Styles
 */
const useStyles = makeStyles<{ params: Partial<Props> }>()((theme, { params }) => ({
  root: {
    flexWrap: params.wrap ? "wrap" : undefined,
    width: params.fullWidth ? "100%" : undefined,
    "& > *:not(:last-child)": {
      marginRight:
        params.spacing !== undefined ? theme.spacing(params.spacing) : theme.spacing(Spacing.m),
      [theme.breakpoints.down("md")]: {
        marginRight: theme.spacing(Spacing.s),
      },
    },
  },
}));

const Group: React.FC<Props> = ({
  children,
  spacing,
  fullWidth,
  justify,
  wrap,
  className,
  ...rest
}: Props) => {
  const params = { wrap, spacing, fullWidth };
  const { classes, cx } = useStyles({ params });

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={justify}
      className={cx(classes.root, className)}
      {...rest}
    >
      {children}
    </Box>
  );
};

export default Group;
