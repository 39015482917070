import { type ApolloError } from "@apollo/client";
import {
  NOTIFICATIONS_SUBSCRIPTION,
  POPUP_NOTIFICATIONS_SUBSCRIPTION,
} from "@hey-lady/gql/subscriptions/notifications";
import { ROWS_PER_PAGE_DEFAULT } from "@hey-lady/shared/helpers/const";
import { useErrorHandler } from "@hey-lady/shared/hooks/errorHandler";
import {
  type ListNotificationsForUserQuery,
  type Maybe,
  type NotificationBaseModel,
  useGetPopupNotificationsQuery,
  useListNotificationsForUserQuery,
  useMarkAllAsSeenMutation,
  useNotificationSeenMutation,
} from "@hey-lady/shared/types/graphql";
import React, { type ReactNode, useCallback, useContext, useEffect } from "react";
import { isUnreadNotification } from "../components/Notifications/utils";

export interface NotificationsContextType {
  markAllAsSeen: () => void;
  markAsSeen: (notificationId: string) => void;
  notificationsError?: ApolloError;
  notificationsLoading: boolean;
  unreadNotifications?: ListNotificationsForUserQuery["listNotificationsForUser"];
  listNotifications?: Maybe<ListNotificationsForUserQuery["listNotificationsForUser"]>;
  popupNotifications: NotificationBaseModel[];
  fetchNextPage: () => void;
}

const NotificationsContext: React.Context<NotificationsContextType> = React.createContext(
  {} as NotificationsContextType,
);

export const useNotifications = () => useContext(NotificationsContext);

interface Props {
  children: ReactNode;
}

export function NotificationsProvider({ children }: Props) {
  const { handleError } = useErrorHandler();
  const [notificationSeenMutation] = useNotificationSeenMutation();
  const [markAllAsSeenMutation] = useMarkAllAsSeenMutation({
    refetchQueries: ["listNotificationsForUser"],
  });
  const markAsSeen = useCallback(
    (id: string) => {
      return handleError(notificationSeenMutation)({ variables: { id } });
    },
    [handleError, notificationSeenMutation],
  );

  const markAllAsSeen = handleError(markAllAsSeenMutation);

  const {
    data: listNotificationsData,
    error: listNotificationsError,
    loading: listNotificationsLoading,
    subscribeToMore: listSubscribeToMore,
    fetchMore: listNotificationsFetchMore,
  } = useListNotificationsForUserQuery({
    variables: {
      cursor: {
        limit: ROWS_PER_PAGE_DEFAULT,
      },
      unreadOnly: false,
    },
    fetchPolicy: "cache-and-network",
  });

  const {
    data: unreadNotificationsData,
    // error: unreadNotificationsError,
    // loading: unreadNotificationsLoading,
    subscribeToMore: unreadSubscribeToMore,
  } = useListNotificationsForUserQuery({
    variables: {
      cursor: {
        limit: ROWS_PER_PAGE_DEFAULT,
      },
      unreadOnly: true,
    },
    fetchPolicy: "cache-and-network",
  });

  const fetchNextPage = useCallback(() => {
    void listNotificationsFetchMore({
      variables: {
        cursor: {
          maxDate: listNotificationsData?.listNotificationsForUser.nextCursor,
          limit: ROWS_PER_PAGE_DEFAULT,
        },
        unreadOnly: false,
      },
    });
  }, [listNotificationsFetchMore, listNotificationsData?.listNotificationsForUser.nextCursor]);

  const {
    data: popupNotificationsData,
    // error: popupNotificationsError,
    // loading: popupNotificationsLoading,
    subscribeToMore: popupSubscribeToMore,
  } = useGetPopupNotificationsQuery({
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    const unsubscribeList = listSubscribeToMore({
      document: NOTIFICATIONS_SUBSCRIPTION,
      updateQuery: (prev: any, { subscriptionData }: any) => {
        if (!subscriptionData?.data?.notificationAdded) return prev;
        const notification = subscriptionData?.data?.notificationAdded;
        const newCache = {
          listNotificationsForUser: {
            ...prev.listNotificationsForUser,
            items: [notification],
            total: prev.listNotificationsForUser.total + 1,
          },
        };
        return newCache;
      },
    });

    const unsubscribeUnread = unreadSubscribeToMore({
      document: NOTIFICATIONS_SUBSCRIPTION,
      updateQuery: (prev: any, { subscriptionData }: any) => {
        if (!subscriptionData?.data?.notificationAdded) return prev;
        const notification = subscriptionData?.data?.notificationAdded;
        if (!isUnreadNotification(notification)) {
          return prev;
        }
        const newCache = {
          listNotificationsForUser: {
            ...prev.listNotificationsForUser,
            items: [notification],
            total: prev.listNotificationsForUser.total + 1,
          },
        };
        return newCache;
      },
    });

    const unsubscribePopup = popupSubscribeToMore({
      document: POPUP_NOTIFICATIONS_SUBSCRIPTION,
      updateQuery: (prev: any, { subscriptionData }: any) => {
        if (!subscriptionData?.data?.popupNotificationAdded) return prev;
        const newNotificationAdded = subscriptionData?.data?.popupNotificationAdded;
        const newCache = {
          getPopupNotifications: [newNotificationAdded, ...prev.getPopupNotifications],
        };
        return newCache;
      },
    });

    return () => {
      unsubscribeList();
      unsubscribeUnread();
      unsubscribePopup();
    };
  }, [listSubscribeToMore, unreadSubscribeToMore, popupSubscribeToMore]);

  return (
    <NotificationsContext.Provider
      value={{
        markAsSeen,
        markAllAsSeen,
        notificationsError: listNotificationsError,
        notificationsLoading: listNotificationsLoading,
        unreadNotifications: unreadNotificationsData?.listNotificationsForUser,
        popupNotifications:
          (popupNotificationsData?.getPopupNotifications as NotificationBaseModel[]) ?? [],
        listNotifications: listNotificationsData?.listNotificationsForUser,
        fetchNextPage,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}
