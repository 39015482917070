import { Box, type Theme } from "@mui/material";
import React, { type ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";

import Typography from "@hey-lady/ui/atoms/Typography";
import config from "../overrides/config";

/**
 * Types
 */
type AlertSeverity = "error" | "warning" | "success";
type TypographyColor = "error" | "primary" | "gray.default";

export interface AlertProps {
  severity?: AlertSeverity;
  children: JSX.Element[] | JSX.Element | ReactNode | string;
}

/**
 * Constants
 */
const colors = new Map<AlertSeverity, TypographyColor>([
  ["error", "error"],
  ["success", "primary"],
  ["warning", "gray.default"],
]);

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: config.radius.s,
    borderColor: theme.palette.green.pea,
    backgroundColor: theme.palette.green.swans,
    paddingTop: theme.spacing(Spacing.sm),
    paddingBottom: theme.spacing(Spacing.sm),
    paddingLeft: theme.spacing(Spacing.ml),
    paddingRight: theme.spacing(Spacing.ml),
  },
  error: {
    borderColor: theme.palette.red.grenadier,
    backgroundColor: theme.palette.pink.peach,
  },
  warning: {
    borderColor: theme.palette.orange.tumbleweed,
    backgroundColor: theme.palette.beige.almond,
  },
  success: {
    borderColor: theme.palette.green.pea,
    backgroundColor: theme.palette.green.swans,
  },
}));

const Alert: React.FC<AlertProps> = ({ children, severity = "success" }: AlertProps) => {
  const { classes, cx } = useStyles();
  const color = colors.get(severity);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      className={cx(classes.root, {
        [classes.error]: severity === "error",
        [classes.success]: severity === "success",
        [classes.warning]: severity === "warning",
      })}
    >
      <Typography variant="caption" color={color} weight={FontWeight.Regular}>
        {children}
      </Typography>
    </Box>
  );
};

export default Alert;
