import { gql } from "@apollo/client";

export const COMMENT_NOTIFICATION_FIELDS = gql`
  fragment CommentNotificationFields on CommentNotificationModel {
    id
    comment {
      id
      targetId
      targetType
      targetArticle {
        title
      }
      targetEvent {
        title
      }
      targetTopic {
        title
      }
    }
    commentAction
    actor {
      id
      name
      firstName
      lastName
      avatarUrl
    }
  }
`;

export const EVENT_NOTIFICATION_FIELDS = gql`
  fragment EventNotificationFields on EventNotificationModel {
    id
    action
    actor {
      id
      name
      firstName
      lastName
      avatarUrl
    }
    event {
      id
      startDate
      title
      kind
      isDeleted
      host {
        id
        name
      }
    }
    invite {
      id
      status
    }
  }
`;

export const CHAT_MENTION_NOTIFICATION_FIELDS = gql`
  fragment ChatMentionNotificationFields on ChatMentionNotificationModel {
    id
    channelId
    chatMessageId
    parentChatMessageId
    chatMessage
    actor {
      id
      name
      firstName
      lastName
      avatarUrl
    }
  }
`;

export const BADGE_NOTIFICATION_FIELDS = gql`
  fragment BadgeNotificationFields on BadgeNotificationModel {
    id
    badge {
      id
      type
      achievedDate
    }
  }
`;

export const PROFILE_UPDATE_NOTIFICATION_FIELDS = gql`
  fragment ProfileUpdateNotificationFields on ProfileUpdateNotificationModel {
    id
    type
  }
`;

export const ARTICLE_NOTIFICATION_FIELDS = gql`
  fragment ArticleNotificationFields on ArticleNotificationModel {
    id
    articleAction
    article {
      id
      title
      author {
        id
        avatarUrl
        name
        firstName
        lastName
      }
    }
  }
`;

export const MONTHLY_REPORT_NOTIFICATION_FIELDS = gql`
  fragment MonthlyReportNotificationFields on MonthlyReportNotificationModel {
    id
    type
  }
`;

export const FRIEND_JOINED_NOTIFICATION_FIELDS = gql`
  fragment FriendJoinedNotificationFields on FriendJoinedNotificationModel {
    id
    user {
      id
      firstName
      lastName
    }
    friend {
      id
      firstName
      lastName
      avatarUrl
    }
    type
  }
`;
