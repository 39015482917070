import * as Sentry from "@sentry/react";
import React, { lazy, Suspense } from "react";
import { Route, RouterProvider, Routes, createBrowserRouter } from "react-router-dom";

import PageLoader from "@hey-lady/ui/atoms/PageLoader";

import NakedLayout from "../layouts/NakedLayout";
import Error404Page from "../pages/Error404Page";
import HeavyLoadPage from "../pages/HeavyLoadPage";
import AuthUserTrackingWrapper from "./AuthUserTrackingWrapper";
import { RouterErrorFallback } from "./RouterErrorFallback";

/**
 * Lazy load routes
 */
const LogoutPage = lazy(() => import("../pages/LogoutPage"));
const SignupPage = lazy(() => import("../pages/SignupPage"));
const ChargebeeHostedPage = lazy(() => import("../pages/Signup/ChargebeeHostedPage"));
const SignupConfirmationPage = lazy(() => import("../pages/SignupConfirmationPage"));
const AuthLayout = lazy(() => import("../layouts/AuthLayout"));
const OnboardLayout = lazy(() => import("../layouts/OnboardLayout"));
const ProtectedRoutes = lazy(() => import("./ProtectedRoutes"));
const ActivateUserPage = lazy(() => import("../pages/ActivateUserPage"));
const ReferralWelcomePage = lazy(() => import("../pages/ReferralWelcomePage"));

const Router: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={RouterErrorFallback}>
      <AuthUserTrackingWrapper>
        <Routes>
          <Route path="404" element={<Error404Page />} />
          <Route path="heavy-load" element={<HeavyLoadPage />} />
          <Route
            path="logout"
            element={
              <Suspense fallback={<PageLoader />}>
                <LogoutPage />
              </Suspense>
            }
          />
          <Route
            path="auth/*"
            element={
              <Suspense fallback={<PageLoader />}>
                <AuthLayout />
              </Suspense>
            }
          />
          <Route
            path="welcome"
            element={
              <NakedLayout size="lg" fullWidth fixed>
                <Suspense fallback={<PageLoader />}>
                  <ReferralWelcomePage />
                </Suspense>
              </NakedLayout>
            }
          />

          <Route
            path="signup/:step?/*"
            element={
              <Suspense fallback={<PageLoader />}>
                <SignupPage />
              </Suspense>
            }
          />
          <Route
            path="signup/confirmation"
            element={
              <Suspense fallback={<PageLoader />}>
                <SignupConfirmationPage />
              </Suspense>
            }
          />
          <Route
            path="signup/checkout"
            element={
              <Suspense fallback={<PageLoader />}>
                <ChargebeeHostedPage />
              </Suspense>
            }
          />
          <Route
            path="onboard/*"
            element={
              <Suspense fallback={<PageLoader />}>
                <OnboardLayout />
              </Suspense>
            }
          />
          <Route
            path="activate/:activationKey"
            element={
              <Suspense fallback={<PageLoader />}>
                <ActivateUserPage />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Suspense fallback={<PageLoader />}>
                <ProtectedRoutes />
              </Suspense>
            }
          />
        </Routes>
      </AuthUserTrackingWrapper>
    </Sentry.ErrorBoundary>
  );
};

const router = createBrowserRouter([{ path: "*", element: <Router /> }]);

export default () => {
  return <RouterProvider router={router} />;
};
