import { Box, type Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";
import { staticFileUrl } from "@hey-lady/shared/helpers/file";
import { useFormatMessages, useSwitch } from "@hey-lady/shared/hooks/general";
import {
  FriendJoinedNotificationModel,
  type NotificationBaseModel,
} from "@hey-lady/shared/types/graphql";

import Avatar from "@hey-lady/ui/atoms/Avatar";
import Button from "@hey-lady/ui/atoms/Button";
import LogoBranding from "@hey-lady/ui/atoms/LogoBranding";
import Modal from "@hey-lady/ui/atoms/Modal";
import Typography from "@hey-lady/ui/atoms/Typography";
import Group from "@hey-lady/ui/molecules/Group";

import { useNotifications } from "@hey-lady/app/context/NotificationsContext";

/**
 * Types
 */
interface Props {
  notification: FriendJoinedNotificationModel;
  notificationId: NotificationBaseModel["id"];
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "460px",
    },
  },
}));

export default function FriendJoinedModal({ notification, notificationId }: Props) {
  const { classes } = useStyles();
  const [isOpen, toggle] = useSwitch(true);
  const { markAsSeen } = useNotifications();

  const chatChannel = `/chat?userID=${notification.friend.id}`;

  const [title, description, sendMessage, inviteAnotherFriend] = useFormatMessages([
    { id: "NOTIFICATION_SIGNED_UP", values: { name: notification.friend.firstName } },
    { id: "NOTIFICATION_GREAT_NEWS", values: { logo: <LogoBranding /> } },
    { id: "NOTIFICATION_SEND_MESSAGE" },
    { id: "NOTIFICATION_INVITE_ANOTHER_FRIEND" },
  ]);

  const closeModal = () => {
    markAsSeen(notificationId);
    toggle();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
        <Avatar src={staticFileUrl(notification.friend?.avatarUrl) ?? undefined} size="lg" />

        <Box my={Spacing.l}>
          <Typography variant="h4" align="center" weight={FontWeight.Semibold}>
            {title}
          </Typography>
        </Box>
        <Box mb={Spacing.xl}>
          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Box>

        <Group spacing={Spacing.m} justify="center">
          <Link to={chatChannel}>
            <Button variant="outlined" color="primary" onClick={closeModal}>
              {sendMessage}
            </Button>
          </Link>
          <Link to="/referral">
            <Button variant="contained" color="primary" onClick={closeModal}>
              {inviteAnotherFriend}
            </Button>
          </Link>
        </Group>
      </Box>
    </Modal>
  );
}
