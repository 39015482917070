import { Box, type Theme, useTheme } from "@mui/material";
import { differenceInMinutes } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages, useSwitch } from "@hey-lady/shared/hooks/general";

import Button from "@hey-lady/ui/atoms/Button";
import Typography from "@hey-lady/ui/atoms/Typography";

import clock from "@hey-lady/shared/static/images/clock@2x.svg";
import {
  type EventNotificationModel,
  type NotificationBaseModel,
} from "@hey-lady/shared/types/graphql";
import Modal from "@hey-lady/ui/atoms/Modal";
import { useNavigate } from "react-router";
import { useNotifications } from "../../../context/NotificationsContext";

/**
 * Types
 */
interface Props {
  eventNotification: EventNotificationModel;
  notificationId: NotificationBaseModel["id"];
}

/**
 * Constants
 */
const ONE_MINUTE_IN_MILLISECONDS = 60000;

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  warningContainer: {
    padding: theme.spacing(Spacing.xxl),
    [theme.breakpoints.up("sm")]: {
      maxWidth: "526px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(Spacing.l),
    },
  },
  image: {
    width: "139px",
    height: "139px",
  },
  event: {
    cursor: "pointer",
  },
}));

export default function EventIsStartingModal({ eventNotification, notificationId }: Props) {
  const theme = useTheme();
  const { classes } = useStyles();
  const { markAsSeen } = useNotifications();
  const navigate = useNavigate();
  const [isOpen, close] = useSwitch(true);
  const [timeUntilEvent, setTimeUntilEvent] = useState<number>(0);

  const closeModal = useCallback(() => {
    markAsSeen(notificationId);
    close();
  }, [close, markAsSeen, notificationId]);

  useEffect(() => {
    if (differenceInMinutes(eventNotification.event.startDate, new Date()) < 0) {
      markAsSeen(notificationId);
      return;
    }
  }, [markAsSeen, notificationId, eventNotification.event.startDate]);

  const renderers = {
    event: () => {
      return (
        <Box onClick={closeModal} className={classes.event} component="span">
          <Typography variant="body1" component="span" color="orange.brandy">
            {eventNotification.event.title}
          </Typography>
        </Box>
      );
    },
  };

  const [eventIsStarting, reminder, viewEvent] = useFormatMessages([
    {
      id: "NOTIFICATIONS_EVENT_IS_STARTING",
    },
    { id: "NOTIFICATIONS_REMINDER", values: { event: renderers.event(), time: timeUntilEvent } },
    { id: "COMMON_VIEW_EVENT" },
  ]);

  useEffect(() => {
    setTimeUntilEvent(differenceInMinutes(eventNotification.event.startDate, new Date()));
    const intervalId = setInterval(() => {
      const minsUntilEvent = differenceInMinutes(eventNotification.event.startDate, new Date());

      if (isOpen && minsUntilEvent < 0) {
        closeModal();
        clearInterval(intervalId);
        return;
      }
      setTimeUntilEvent(minsUntilEvent);
    }, ONE_MINUTE_IN_MILLISECONDS);

    return () => {
      clearInterval(intervalId);
    };
  }, [eventNotification.event.startDate, isOpen, closeModal]);

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        className={classes.warningContainer}
      >
        <img src={clock} className={classes.image} alt="clock" />

        <Box mb={Spacing.ml}>
          <Typography variant="subtitle2" color="primary" align="center">
            {eventIsStarting}
          </Typography>
        </Box>
        <Box mb={Spacing.l}>
          <Typography variant="body2" align="center" htmlColor={theme.palette.gray.emperor}>
            {reminder}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={Spacing.m}>
          <Button
            variant="contained"
            onClick={() => {
              closeModal();
              navigate(`/event/${eventNotification.event.id}`);
            }}
          >
            {viewEvent}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
