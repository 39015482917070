import { useQuery } from "@apollo/client";
import { Box, Container, IconButton, type Theme, useMediaQuery, useTheme } from "@mui/material";
import { not } from "ramda";
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { isFuture } from "date-fns";

import { ME } from "@hey-lady/gql/queries/user";
import { EventPermissions } from "@hey-lady/shared/types/graphql";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { openWidget } from "@hey-lady/shared/helpers/utils";

import Loader from "@hey-lady/ui/atoms/Loader";
import ErrorAlert from "@hey-lady/ui/molecules/ErrorAlert";

import IconHelp from "@hey-lady/ui/icons/IconHelp";

import NakedAppBar from "../components/LayoutComponents/NakedAppBar";
import LiveTableStartedMessage from "../components/LiveTables/LiveTableStartedMessage";
import NotificationsModalDisplay from "../components/Notifications/NotificationsModalDisplay";

/**
 * Types
 */
interface Props {
  fixed?: boolean;
  absolute?: boolean;
  headless?: boolean;
  authenticated?: boolean;
  homeRedirect?: boolean;
  naked?: boolean;
  size?: "sm" | "md" | "lg";
  children: JSX.Element[] | JSX.Element;
  showWidget?: boolean;
  fullWidth?: boolean;
  hideLogo?: boolean;
  showLogoOnMobile?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles<{
  homeRedirect: Props["homeRedirect"];
  fullWidth: Props["fullWidth"];
}>()((theme: Theme, { homeRedirect, fullWidth }) => ({
  root: {
    flex: "auto",
    display: "flex",
    minHeight: "100vh",
    alignItems: homeRedirect ? "flex-start" : "center",
    justifyContent: "center",
    padding: fullWidth ? "unset" : theme.spacing(Spacing.xl, Spacing.xl, Spacing.xxl),
    [theme.breakpoints.down("md")]: {
      alignItems: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      padding: fullWidth ? "unset" : theme.spacing(Spacing.ml),
    },
  },
  footer: {
    position: "fixed",
    bottom: "0",
    padding: theme.spacing(Spacing.xl),
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(Spacing.m),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(Spacing.xs),
    },
  },
}));

const NakedLayout: React.FC<Props> = ({
  fixed,
  absolute,
  size = "sm",
  headless,
  authenticated,
  children,
  homeRedirect,
  naked,
  showWidget,
  fullWidth,
  hideLogo,
  showLogoOnMobile,
}: Props) => {
  const { classes } = useStyles({ homeRedirect, fullWidth });
  const theme = useTheme();
  const matched = useMediaQuery(theme.breakpoints.up("lg"));

  const { data, error, loading } = useQuery(ME, { skip: not(authenticated) });

  const location = useLocation();

  const isPaused = isFuture(data?.me.pausedUntil);

  if (authenticated && isPaused && location.pathname !== "/settings/membership-settings") {
    return <Navigate to="/settings/membership-settings" />;
  }

  if (loading) {
    return <Loader />;
  }
  if (error) {
    return <ErrorAlert error={error} />;
  }

  const renderers = {
    navbar: () => {
      if (not(headless) && authenticated) {
        return (
          <NakedAppBar
            homeRedirect={homeRedirect}
            naked={naked}
            fixed={fixed}
            hideLogo={hideLogo}
            absolute={absolute}
            showLogoOnMobile={showLogoOnMobile}
          />
        );
      }
      if (not(headless) && not(authenticated)) {
        return <>{matched ? <NakedAppBar fixed={fixed} /> : null}</>;
      }
      return null;
    },
  };

  return (
    <>
      {renderers.navbar()}

      <Box className={classes.root}>
        {fullWidth ? children : <Container maxWidth={size}>{children}</Container>}
      </Box>

      {showWidget && (
        <Box className={classes.footer}>
          <IconButton onClick={openWidget}>
            <IconHelp htmlColor={theme.palette.green.pea} />
          </IconButton>
        </Box>
      )}

      {authenticated && data?.me.kind !== EventPermissions.InactiveMember && (
        <NotificationsModalDisplay />
      )}
      {authenticated && data?.me.kind !== EventPermissions.InactiveMember && (
        <LiveTableStartedMessage />
      )}
    </>
  );
};

export default NakedLayout;
