import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl, FormControlLabel, Radio, RadioGroup, type Theme } from "@mui/material";
import { useSnackbar } from "notistack";
import { not } from "ramda";
import React from "react";
import { Controller, type ControllerRenderProps, useForm } from "react-hook-form";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";
import * as yup from "yup";

import {
  MeDocument,
  NotificationBaseModel,
  useUpdateMeMutation,
} from "@hey-lady/shared/types/graphql";

import { AVAILABILITY_DEFAULTS, NeedPartner } from "@hey-lady/shared/helpers/const";
import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages, useOnOffSwitch } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";

import Button from "@hey-lady/ui/atoms/Button";
import Modal from "@hey-lady/ui/atoms/Modal";
import Typography from "@hey-lady/ui/atoms/Typography";
import Group from "@hey-lady/ui/molecules/Group";

import image from "@hey-lady/shared/static/images/update-availabilities.png";

import { useNotifications } from "@hey-lady/app/context/NotificationsContext";
import HoursGroup from "../../Users/HoursGroup";

/**
 * Types
 */
const availabilitySchema = yup.object().shape({
  from: yup.string().required(),
  until: yup.string().required(),
});

/**
 * Schema
 */
const schema = yup.object().shape({
  needSpeakingPartner: yup.bool().required(),
  weekdays: availabilitySchema.required(),
  weekends: availabilitySchema.required(),
});

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(Spacing.xs, Spacing.m, Spacing.m, Spacing.m),
    marginTop: theme.spacing(Spacing.xxl),
    maxWidth: "800px",
    maxHeight: "80vh",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      maxHeight: "unset",
    },
  },
  image: {
    width: "220px",
    height: "auto",
  },
}));

export interface Props {
  notification: NotificationBaseModel;
}

export default function UpdateAvailabilityNotification({ notification }: Props) {
  const { classes } = useStyles();
  const [isOpen, open, close] = useOnOffSwitch(false);
  const { enqueueSnackbar } = useSnackbar();
  const { markAsSeen } = useNotifications();

  const closeModal = () => {
    markAsSeen(notification.id);
    close();
  };

  const { me } = useAuth();
  const [update, { loading }] = useUpdateMeMutation({ refetchQueries: [MeDocument] });

  const defaultValues = {
    needSpeakingPartner: me.needSpeakingPartner ?? false,
    weekdays: me.availability
      ? { from: me.availability[0].from, until: me.availability[0].until }
      : AVAILABILITY_DEFAULTS[0],
    weekends: me.availability
      ? { from: me.availability[1].from, until: me.availability[1].until }
      : AVAILABILITY_DEFAULTS[1],
  };
  type FormValues = typeof defaultValues;

  const { control, formState, handleSubmit } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onSubmit = async (form: FormValues) => {
    const variables = {
      input: {
        needSpeakingPartner: form.needSpeakingPartner,
        availability: [
          { from: form.weekdays.from, until: form.weekdays.until },
          { from: form.weekends.from, until: form.weekends.until },
        ],
      },
    };

    try {
      await update({ variables });
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      closeModal();
    }
  };

  const [
    title,
    description,
    areYouOpen,
    yes,
    no,
    isThisAvailability,
    mondayThursday,
    fridaySunday,
    saveDetails,
    cancel,
  ] = useFormatMessages([
    { id: "NOTIFICATION_IS_THIS_STILL_CORRECT", values: { name: me?.firstName } },
    { id: "NOTIFICATION_THIS_WILL_HELP_US" },
    { id: "NOTIFICATION_ARE_YOU_OPEN" },
    { id: "COMMON_YES" },
    { id: "COMMON_NO" },
    { id: "NOTIFICATION_IS_THIS_YOUR_AVAILABILITY" },
    { id: "ONBOARD_MONDAY_THURSDAY" },
    { id: "ONBOARD_FRIDAY_SUNDAY" },
    { id: "NOTIFICATION_SAVE_DETAILS" },
    { id: "COMMON_CANCEL" },
  ]);

  const renderers = {
    radio: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "needSpeakingPartner"> }) => {
      return (
        <RadioGroup
          row
          value={value ? NeedPartner.Yes : NeedPartner.No}
          onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
            onChange(target.value === NeedPartner.Yes);
          }}
        >
          <Box mr={Spacing.l}>
            <FormControlLabel
              data-test-id="need-partner"
              value={NeedPartner.Yes}
              label={yes}
              control={<Radio />}
            />
          </Box>
          <FormControlLabel
            data-test-id="dont-need-partner"
            value={NeedPartner.No}
            label={no}
            control={<Radio />}
          />
        </RadioGroup>
      );
    },
    weekdays: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "weekdays"> }) => {
      return (
        <HoursGroup
          title={mondayThursday}
          from={value.from}
          until={value.until}
          onChange={(hoursField, hoursValue) => {
            onChange({ ...value, [hoursField]: hoursValue });
          }}
        />
      );
    },
    weekends: ({
      field: { value, onChange },
    }: { field: ControllerRenderProps<FormValues, "weekends"> }) => {
      return (
        <HoursGroup
          title={fridaySunday}
          from={value.from}
          until={value.until}
          onChange={(hoursField, hoursValue) => {
            onChange({ ...value, [hoursField]: hoursValue });
          }}
        />
      );
    },
  };

  const userCreatedAfterNotification = me.createdAt && me.createdAt > notification.createdAt;

  useMount(() => {
    if (!me.isVerified || userCreatedAfterNotification) {
      markAsSeen(notification.id);
    } else {
      open();
    }
  });

  return (
    <Modal open={isOpen} onClose={closeModal} fullSize>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
          <img src={image} className={classes.image} alt="update availaibility notification" />

          <Box mt={Spacing.xl}>
            <Typography variant="h3" align="center" weight={FontWeight.Semibold}>
              {title}
            </Typography>
          </Box>
          <Box mb={Spacing.xl}>
            <Typography variant="body2" align="center">
              {description}
            </Typography>
          </Box>
          <Box mb={Spacing.m}>
            <Typography variant="h6" align="center" weight={FontWeight.Semibold}>
              {areYouOpen}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <FormControl>
              <Controller control={control} name="needSpeakingPartner" render={renderers.radio} />
            </FormControl>
          </Box>

          <Box my={Spacing.xl}>
            <Typography variant="h6" align="center" weight={FontWeight.Semibold}>
              {isThisAvailability}
            </Typography>
          </Box>
          <Box>
            <FormControl>
              <Controller control={control} name="weekdays" render={renderers.weekdays} />
            </FormControl>
            <FormControl>
              <Controller control={control} name="weekends" render={renderers.weekends} />
            </FormControl>
          </Box>
          <Group spacing={Spacing.m} justify="center">
            <Button variant="outlined" color="primary" onClick={closeModal}>
              {cancel}
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={not(formState.isValid) || loading}
            >
              {saveDetails}
            </Button>
          </Group>
        </Box>
      </form>
    </Modal>
  );
}
