import Typography, { Props as TypographyProps } from "@hey-lady/ui/atoms/Typography";
import React from "react";
import { makeStyles } from "tss-react/mui";

/**
 * Types
 */
interface Props extends TypographyProps {
  href?: string;
  target?: string;
}

/**
 * Styles
 */
const useStyles = makeStyles()({
  underline: {
    textDecoration: "underline",
  },
});

const StyledLink: React.FC<Props> = ({
  href,
  target = "_blank",
  children,
  className,
  ...other
}) => {
  const { classes, cx } = useStyles();

  return (
    <a href={href} target={target}>
      <Typography
        color="orange.brandy"
        component="span"
        className={cx([classes.underline, className && className])}
        {...other}
      >
        {children}
      </Typography>
    </a>
  );
};

export default StyledLink;
