import { Box, type Theme } from "@mui/material";
import { Link } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages, useSwitch } from "@hey-lady/shared/hooks/general";
import {
  FriendJoinedNotificationModel,
  type NotificationBaseModel,
} from "@hey-lady/shared/types/graphql";

import Button from "@hey-lady/ui/atoms/Button";
import LogoBranding from "@hey-lady/ui/atoms/LogoBranding";
import Modal from "@hey-lady/ui/atoms/Modal";
import Typography from "@hey-lady/ui/atoms/Typography";
import Group from "@hey-lady/ui/molecules/Group";

import image from "@hey-lady/shared/static/images/events/conversation-starter@2x.png";

import { useNotifications } from "@hey-lady/app/context/NotificationsContext";

/**
 * Types
 */
interface Props {
  notification: FriendJoinedNotificationModel;
  notificationId: NotificationBaseModel["id"];
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "460px",
    },
  },
  image: {
    width: "142px",
    height: "auto",
    transform: "rotateY(180deg)",
  },
}));

export default function FriendAcceptedInviteModal({ notification, notificationId }: Props) {
  const { classes } = useStyles();
  const [isOpen, toggle] = useSwitch(true);
  const { markAsSeen } = useNotifications();

  const chatChannel = `/chat?userID=${notification.friend.id}`;

  const [title, description, welcome, sendMessage, inviteAnotherFriend] = useFormatMessages([
    { id: "NOTIFICATION_ACCEPTED_INVITATION", values: { name: notification.friend.firstName } },
    {
      id: "NOTIFICATION_JUST_JOINED",
      values: {
        b: (str) => <b>{str}</b>,
        name: notification.friend.firstName,
        logo: <LogoBranding />,
      },
    },
    { id: "NOTIFICATION_MAKE_HER_FEEL_WELCOME" },
    { id: "NOTIFICATION_SEND_MESSAGE" },
    { id: "NOTIFICATION_INVITE_ANOTHER_FRIEND" },
  ]);

  const closeModal = () => {
    markAsSeen(notificationId);
    toggle();
  };

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
        <img src={image} className={classes.image} alt="illustration" />

        <Box my={Spacing.l}>
          <Typography variant="h4" align="center" weight={FontWeight.Semibold}>
            {title}
          </Typography>
        </Box>
        <Box mb={Spacing.s}>
          <Typography variant="body2" align="center">
            {description}
          </Typography>
        </Box>
        <Box mb={Spacing.xl}>
          <Typography variant="body2" align="center">
            {welcome}
          </Typography>
        </Box>

        <Group spacing={Spacing.m} justify="center">
          <Link to={chatChannel}>
            <Button variant="outlined" color="primary" onClick={closeModal}>
              {sendMessage}
            </Button>
          </Link>
          <Link to="/referral">
            <Button variant="contained" color="primary" onClick={closeModal}>
              {inviteAnotherFriend}
            </Button>
          </Link>
        </Group>
      </Box>
    </Modal>
  );
}
