import { Button, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages } from "@hey-lady/shared/hooks/general";

import Typography from "@hey-lady/ui/atoms/Typography";

/**
 * Props
 */
interface Props {
  tableId: string;
  setMuted: React.Dispatch<React.SetStateAction<Date | null | undefined>>;
  closeMessage: () => void;
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme) => ({
  button: {
    padding: theme.spacing(Spacing.xs, Spacing.ml),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(Spacing.xs, Spacing.ml),
    },
  },
  grabSeatBtn: {
    marginRight: theme.spacing(Spacing.sm),
  },
}));

const LiveTableStartedMessageActions: React.FC<Props> = ({ tableId, setMuted, closeMessage }) => {
  const { classes, cx } = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [grabSeatBtn, muteBtn, goToTablesBtn] = useFormatMessages([
    { id: "LIVE_TABLES_GRAB_A_SEAT" },
    { id: "LIVE_TABLES_STARTED_MESSAGE_MUTE" },
    { id: "LIVE_TABLES_STARTED_MESSAGE_GO_TO_TABLES" },
  ]);

  const mute = () => {
    setMuted(new Date());
    closeMessage();
  };

  const grabSeat = () => {
    navigate(`/table/${tableId}`);
    closeMessage();
  };

  const goToTables = () => {
    navigate("/events/live-tables");
    closeMessage();
  };

  return matchesSm ? (
    <Button variant="contained" className={classes.button} onClick={goToTables}>
      <Typography variant="caption" component="span" weight={FontWeight.Regular} color="inherit">
        {goToTablesBtn}
      </Typography>
    </Button>
  ) : (
    <>
      <Button
        variant="contained"
        className={cx([classes.button, classes.grabSeatBtn])}
        onClick={grabSeat}
      >
        <Typography variant="caption" component="span" weight={FontWeight.Regular} color="inherit">
          {grabSeatBtn}
        </Typography>
      </Button>
      <Button variant="contained" color="white" className={classes.button} onClick={mute}>
        <Typography variant="caption" component="span" weight={FontWeight.Regular} color="inherit">
          {muteBtn}
        </Typography>
      </Button>
    </>
  );
};

export default LiveTableStartedMessageActions;
