import loadingAnimation from "@hey-lady/shared/static/images/loader/loading-animation.svg";
import React from "react";

const LoaderAnimation: React.FC = () => {
  return (
    <object type="image/svg+xml" data={loadingAnimation}>
      Loading...
    </object>
  );
};

export default LoaderAnimation;
