import { useMediaQuery, useTheme } from "@mui/material";
import React from "react";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages } from "@hey-lady/shared/hooks/general";

import Typography from "@hey-lady/ui/atoms/Typography";

/**
 * Props
 */
interface Props {
  tableCreatorName: string;
  tableTitle: string;
}

const LiveTableStartedMessageTitle: React.FC<Props> = ({ tableCreatorName, tableTitle }) => {
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [title] = useFormatMessages([
    { id: "LIVE_TABLES_STARTED_MESSAGE_TITLE", values: { name: tableCreatorName } },
  ]);

  return matchesSm ? (
    <Typography mr={Spacing.sm}>{title}</Typography>
  ) : (
    <>
      <Typography>{title}:</Typography>
      <Typography mb={Spacing.sm}>{tableTitle}</Typography>
    </>
  );
};

export default LiveTableStartedMessageTitle;
