import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { makeStyles } from "tss-react/mui";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { UserBadgeType } from "@hey-lady/shared/types/graphql";

import Button from "@hey-lady/ui/atoms/Button";
import IconArrowLeft from "@hey-lady/ui/icons/IconArrowLeft";

/**
 * Types
 */
interface Props {
  badgeType: UserBadgeType;
  handleClick: () => void;
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme) => ({
  inviteFriendButton: {
    marginBottom: theme.spacing(Spacing.m),
  },
  backHomeButton: {
    marginTop: theme.spacing(Spacing.m),
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(Spacing.xs),
    },
  },
}));

const BadgeNotificationModalActions: React.FC<Props> = ({ badgeType, handleClick }) => {
  const { classes } = useStyles();

  const location = useLocation();
  const isNotHomePage = !(location.pathname === "/home" || location.pathname === "/");

  const withMyRewardsButton =
    badgeType === UserBadgeType.Ambassador || badgeType === UserBadgeType.PlatinumAmbassador;

  const withInviteFriendButton =
    badgeType === UserBadgeType.BroughtAFriend ||
    badgeType === UserBadgeType.Ambassador ||
    badgeType === UserBadgeType.PlatinumAmbassador;

  return (
    <>
      {withInviteFriendButton && (
        <Link to="/referral">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClick}
            className={classes.inviteFriendButton}
          >
            <FormattedMessage id="NOTIFICATION_INVITE_ANOTHER_FRIEND" />
          </Button>
        </Link>
      )}
      {withMyRewardsButton ? (
        <Link to="/referral/rewards">
          <Button variant="text" color="secondary" onClick={handleClick}>
            <FormattedMessage id="REFERRAL_SEE_MY_REWARDS" />
          </Button>
        </Link>
      ) : (
        <Link to="/account/profile/badges">
          <Button
            variant={badgeType === UserBadgeType.BroughtAFriend ? "text" : "contained"}
            color={badgeType === UserBadgeType.BroughtAFriend ? "secondary" : "primary"}
            onClick={handleClick}
          >
            <FormattedMessage
              id={
                badgeType === UserBadgeType.DiamondAmbassador
                  ? "ACCOUNT_PAGE_THANK_YOU"
                  : "BADGE_SEE_MY_ACHIEVEMENTS"
              }
            />
          </Button>
        </Link>
      )}
      {isNotHomePage && (
        <Link to="/home">
          <Button
            startIcon={<IconArrowLeft />}
            variant="text"
            onClick={handleClick}
            className={classes.backHomeButton}
          >
            <FormattedMessage id="COMMON_BACK_TO_HOME_PAGE" />
          </Button>
        </Link>
      )}
    </>
  );
};
export default BadgeNotificationModalActions;
