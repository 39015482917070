import { Box } from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";
import LoaderAnimation from "./LoaderAnimation";

/**
 * Props
 */

interface Props {
  className?: string;
}

/**
 * Styles
 */

const useStyles = makeStyles()({
  root: {
    width: "60px",
    marginLeft: "auto",
    marginRight: "auto",
  },
});

const Loader: React.FC<Props> = ({ className }) => {
  const { classes, cx } = useStyles();

  return (
    <Box className={cx([classes.root, className])}>
      <LoaderAnimation />
    </Box>
  );
};

export default Loader;
