import { Box, CircularProgress, type Theme, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { isNil, not } from "ramda";
import React, { type ReactNode } from "react";
import { makeStyles } from "tss-react/mui";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useOnOffSwitch } from "@hey-lady/shared/hooks/general";

import Typography from "@hey-lady/ui/atoms/Typography";

/**
 * Types
 */
interface Props {
  size?: "xs" | "sm" | "md" | "lg";
  progress?: number;
  src?: string;
  achieved?: boolean;
  title?: ReactNode;
}

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  sizeXs: {
    width: "50px",
    height: "50px",
  },
  sizeSm: {
    width: "50px",
    height: "50px",
    [theme.breakpoints.down("sm")]: {
      width: "70px",
      height: "70px",
    },
  },
  sizeMd: {
    width: "90px",
    height: "90px",
  },
  sizeLg: {
    width: "120px",
    height: "120px",
    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
    },
  },
  locked: {
    filter: "grayscale(100%)",
  },
}));

const UserBadge: React.FC<Props> = ({ size = "sm", progress, src, achieved, title }: Props) => {
  const theme = useTheme();
  const { classes, cx } = useStyles();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [open, onOpen, onClose] = useOnOffSwitch();

  const progressSize = (): number => {
    if (size === "md") return 110;
    return matches ? 80 : 60;
  };

  if (not(isNil(progress))) {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <Box p={Spacing.xs} display="flex">
          <CircularProgress
            value={progress && progress > 100 ? 100 : progress}
            color="secondary"
            variant="determinate"
            size={progressSize()}
            thickness={size === "md" ? 0.5 : 1}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={src}
              alt="badge"
              className={cx({
                [classes.sizeSm]: size === "sm",
                [classes.sizeMd]: size === "md",
                [classes.locked]: not(achieved),
              })}
            />
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <>
      {title ? (
        <Tooltip
          arrow
          open={open}
          onOpen={onOpen}
          onClose={onClose}
          placement="bottom"
          title={
            <Typography variant="body2" color="white">
              {title}
            </Typography>
          }
        >
          <img
            src={src}
            alt="badge"
            className={cx(classes.sizeXs, { [classes.locked]: not(achieved) })}
            onClick={open ? onClose : onOpen}
          />
        </Tooltip>
      ) : (
        <img
          src={src}
          alt="badge"
          className={cx({
            [classes.sizeXs]: size === "xs",
            [classes.sizeSm]: size === "sm",
            [classes.sizeMd]: size === "md",
            [classes.sizeLg]: size === "lg",
            [classes.locked]: not(achieved),
          })}
        />
      )}
    </>
  );
};

export default UserBadge;
