import { differenceInMinutes } from "date-fns";
import React from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "react-use";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Slide,
  SlideProps,
  Snackbar,
  type Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { staticFileUrl } from "@hey-lady/shared/helpers/file";
import { useOnOffSwitch } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";
import {
  EventPermissions,
  useGetUserSettingsQuery,
  useLiveTableHasStartedSubscription,
} from "@hey-lady/shared/types/graphql";

import Avatar from "@hey-lady/ui/atoms/Avatar";

import LiveTableStartedMessageActions from "./LiveTableStartedMessageActions";
import LiveTableStartedMessageTitle from "./LiveTableStartedMessageTitle";

/**
 * Who should not see this notification:

    - Users who are in a Daily call.
    - Users who are on the Home Page
    - Users on the the Live Tables Tab of the Events Page /events/live-tables
    - Users who are on Login page
    - Users who are in Chargebee Checkout or Portal or Cancel Subscription flow
    - Users who are signing up
    - Users who turned off the notifications in the settings
    - Users who muted for 60 minutes

  Who should see this?

    - Users who are anywhere else including:

      Members Page
      Topics
      Memberzine
      Events page - all tabs except Live Tables
      Events Creation
      Profile - all tabs
      Account Settings pages
*/

/**
 * Constants
 */
const MUTE_LIVE_TABLE_STARTED_MESSAGE_KEY = "hey-lady-live-table-started-message-muted";

/**
 * Helpers
 */

const SlideTransitionComponent = (props: SlideProps) => {
  return <Slide {...props} direction="left" />;
};

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    "&.MuiSnackbar-root": {
      [theme.breakpoints.down("sm")]: {
        top: 0,
        left: 0,
        right: 0,
        borderRadius: 0,
      },
    },
    "& .MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        justifyContent: "center",
        borderRadius: 0,
      },
    },
    "& .MuiSnackbarContent-root": {
      alignItems: "flex-start",
      padding: theme.spacing(Spacing.l),
      backgroundColor: theme.palette.green.light,
      color: theme.palette.gray.default,
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(Spacing.sm),
      },
    },
    "& .MuiSnackbarContent-message": {
      paddingLeft: theme.spacing(Spacing.s),
      [theme.breakpoints.down("sm")]: {
        padding: 0,
      },
    },
  },
  messageBox: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
    },
  },
  messageContent: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      alignItems: "center",
    },
  },
  avatar: {
    marginRight: theme.spacing(Spacing.m),
    [theme.breakpoints.down("sm")]: {
      width: "36px",
      height: "36px",
      marginRight: theme.spacing(Spacing.sm),
    },
  },
}));

const LiveTableStartedMessage: React.FC = () => {
  const { classes } = useStyles();
  const theme = useTheme();
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm"));

  const { me } = useAuth();

  const location = useLocation();

  const { data: userSettings } = useGetUserSettingsQuery();

  const [messageMutedAt, setMessageMutedAt] = useLocalStorage<Date | null>(
    MUTE_LIVE_TABLE_STARTED_MESSAGE_KEY,
    null,
  );
  const [isOpen, open, close] = useOnOffSwitch(false);

  const notificationsOn =
    userSettings?.getUserSettings.receiveLiveTableStartedNotifications ?? true;
  const isValidPage = !["/home", "/events/live-tables"].includes(location.pathname);
  const isNotMuted = !messageMutedAt || differenceInMinutes(new Date(), messageMutedAt) > 60;
  const isUserValid =
    me.isVerified && me.stepsCompleted && me.kind !== EventPermissions.InactiveMember;
  const shouldNotify = notificationsOn && isNotMuted && isValidPage && isUserValid;

  const { data: liveTableData } = useLiveTableHasStartedSubscription({
    onData: ({ data }) => {
      const creatorId = data.data?.liveTableHasStarted.tableCreatorId;
      if (shouldNotify && me.id !== creatorId) {
        open();
      }
    },
  });

  const liveTableHasStarted = liveTableData?.liveTableHasStarted;

  if (!liveTableHasStarted) {
    return null;
  }

  return (
    <Snackbar
      className={classes.root}
      open={isOpen}
      autoHideDuration={10000}
      TransitionComponent={SlideTransitionComponent}
      onClose={close}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      action={
        matchesSm ? undefined : (
          <IconButton size="small" aria-label="close" color="inherit" onClick={close}>
            <CloseIcon fontSize="small" />
          </IconButton>
        )
      }
      message={
        <Box className={classes.messageBox}>
          <Avatar className={classes.avatar} src={staticFileUrl(liveTableHasStarted.avatarUrl)} />
          <Box className={classes.messageContent}>
            <LiveTableStartedMessageTitle
              tableCreatorName={liveTableHasStarted.name ?? ""}
              tableTitle={liveTableHasStarted.tableTitle}
            />
            <LiveTableStartedMessageActions
              tableId={liveTableHasStarted.tableId}
              setMuted={setMessageMutedAt}
              closeMessage={close}
            />
          </Box>
        </Box>
      }
    />
  );
};

export default LiveTableStartedMessage;
