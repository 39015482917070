import CssBaseline from "@mui/material/CssBaseline";
import { StyledEngineProvider, ThemeProvider, createTheme } from "@mui/material/styles";
import React, { type PropsWithChildren } from "react";

import config from "@hey-lady/ui/overrides/config";

import MuiAccordion from "@hey-lady/ui/overrides/MuiAccordion";
import MuiAccordionDetails from "@hey-lady/ui/overrides/MuiAccordionDetails";
import MuiAccordionSummary from "@hey-lady/ui/overrides/MuiAccordionSummary";
import MuiAppBar from "@hey-lady/ui/overrides/MuiAppBar";
import MuiAutocomplete from "@hey-lady/ui/overrides/MuiAutocomplete";
import MuiAvatar from "@hey-lady/ui/overrides/MuiAvatar";
import MuiButton from "@hey-lady/ui/overrides/MuiButton";
import MuiCheckbox from "@hey-lady/ui/overrides/MuiCheckbox";
import MuiChip from "@hey-lady/ui/overrides/MuiChip";
import MuiContainer from "@hey-lady/ui/overrides/MuiContainer";
import MuiCssBaseline from "@hey-lady/ui/overrides/MuiCssBaseline";
import MuiDateCalendar from "@hey-lady/ui/overrides/MuiDateCalendar";
import MuiDivider from "@hey-lady/ui/overrides/MuiDivider";
import MuiDrawer from "@hey-lady/ui/overrides/MuiDrawer";
import MuiFormControl from "@hey-lady/ui/overrides/MuiFormControl";
import MuiFormControlLabel from "@hey-lady/ui/overrides/MuiFormControlLabel";
import MuiFormHelperText from "@hey-lady/ui/overrides/MuiFormHelperText";
import MuiInput from "@hey-lady/ui/overrides/MuiInput";
import MuiInputBase from "@hey-lady/ui/overrides/MuiInputBase";
import MuiInputLabel from "@hey-lady/ui/overrides/MuiInputLabel";
import MuiLinearProgress from "@hey-lady/ui/overrides/MuiLinearProgress";
import MuiList from "@hey-lady/ui/overrides/MuiList";
import MuiListItem from "@hey-lady/ui/overrides/MuiListItem";
import MuiListItemButton from "@hey-lady/ui/overrides/MuiListItemButton";
import MuiMenuItem from "@hey-lady/ui/overrides/MuiMenuItem";
import MuiModal from "@hey-lady/ui/overrides/MuiModal";
import MuiOutlinedInput from "@hey-lady/ui/overrides/MuiOutlinedInput";
import MuiPaper from "@hey-lady/ui/overrides/MuiPaper";
import MuiPickersLayout from "@hey-lady/ui/overrides/MuiPickersLayout";
import MuiRadio from "@hey-lady/ui/overrides/MuiRadio";
import MuiSelect from "@hey-lady/ui/overrides/MuiSelect";
import MuiSlider from "@hey-lady/ui/overrides/MuiSlider";
import MuiSvgIcon from "@hey-lady/ui/overrides/MuiSvgIcon";
import MuiSwitch from "@hey-lady/ui/overrides/MuiSwitch";
import MuiTableCell from "@hey-lady/ui/overrides/MuiTableCell";
import MuiTextField from "@hey-lady/ui/overrides/MuiTextField";
import MuiTypography from "@hey-lady/ui/overrides/MuiTypography";

import { fillArray } from "@hey-lady/shared/helpers/utils";

/**
 * Typography
 */
const typography = {
  fontFamily: config.typography.family.sansSerif,
  fontFamilySerif: config.typography.family.serif,
};

/**
 * Overrides default styles
 */
const overrides = {
  MuiCssBaseline,
  MuiAvatar,
  MuiAppBar,
  MuiSlider,
  MuiContainer,
  MuiSvgIcon,
  MuiFormControl,
  MuiFormHelperText,
  MuiFormControlLabel,
  MuiSelect,
  MuiListItem,
  MuiListItemButton,
  MuiList,
  MuiMenuItem,
  MuiCheckbox,
  MuiInputLabel,
  MuiSwitch,
  MuiInputBase,
  MuiInput,
  MuiRadio,
  MuiButton,
  MuiPaper,
  MuiTextField,
  MuiLinearProgress,
  MuiDivider,
  MuiDrawer,
  MuiTypography,
  MuiChip,
  MuiAccordion,
  MuiAccordionDetails,
  MuiAccordionSummary,
  MuiAutocomplete,
  MuiOutlinedInput,
  MuiTableCell,
  MuiModal,
  MuiDateCalendar,
  MuiPickersLayout,
};

/**
 * Theme
 */
const theme = createTheme({
  palette: config.colors,
  components: overrides,
  typography,
  shape: config.shape,
  breakpoints: config.breakpoints,
  // Specify at least 12 values so the MUI spacing validation doesn't complain while specifing `Grid` `spacing` values
  spacing: Object.values(config.spacing).concat(fillArray(4, config.spacing.xxl)),
});

const Theme: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default Theme;
