import { Box, type Theme, useMediaQuery, useTheme } from "@mui/material";
import { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { useMount } from "react-use";
import { makeStyles } from "tss-react/mui";

import { COMPLETED_FIELDS, MISSING_FIELDS } from "@hey-lady/shared/helpers/const";
import { FontWeight, Spacing, UserFields } from "@hey-lady/shared/helpers/enum";
import { incompleteFieldsOnUserProfile } from "@hey-lady/shared/helpers/utils";
import { useFormatMessages, useSwitch } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";
import { NotificationBaseModel } from "@hey-lady/shared/types/graphql";

import Button from "@hey-lady/ui/atoms/Button";
import Modal from "@hey-lady/ui/atoms/Modal";
import Typography from "@hey-lady/ui/atoms/Typography";

import PerfectProfile from "@hey-lady/shared/static/images/badges/CompleteProfile.svg";
import checked from "@hey-lady/shared/static/images/checked.svg";
import exclamation from "@hey-lady/shared/static/images/exclamation-mark.svg";

import { useNotifications } from "@hey-lady/app/context/NotificationsContext";
import UserBadge from "../../Badges/UserBadge";

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(Spacing.xxl),
    padding: theme.spacing(Spacing.l),
    maxWidth: "800px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "unset",
    },
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "50%",
    marginRight: theme.spacing(Spacing.xxl),
    paddingRight: theme.spacing(Spacing.l),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "unset",
      paddingRight: "unset",
    },
  },
  icon: {
    marginRight: theme.spacing(Spacing.s),
  },
}));

export interface Props {
  notification: NotificationBaseModel;
}

export default function CompleteYourProfileNotification({ notification }: Props) {
  const { classes } = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const { markAsSeen } = useNotifications();

  const { me } = useAuth();
  const [isOpen, switchOpen] = useSwitch(false);

  const closeModal = () => {
    markAsSeen(notification.id);
    switchOpen();
  };

  const incompleteFields = incompleteFieldsOnUserProfile(me);

  const userCreatedAfterNotification = me.createdAt && me.createdAt > notification.createdAt;

  useMount(() => {
    if (me.completedPercentage === 100 || !me.isVerified || userCreatedAfterNotification) {
      markAsSeen(notification.id);
    } else {
      switchOpen();
    }
  });

  const completedFields = Object.values(UserFields).filter(
    (field) => !incompleteFields.includes(field),
  );

  const renderers = {
    orange: (text: ReactNode) => {
      return (
        <Typography
          variant="h3"
          weight={FontWeight.Semibold}
          component="span"
          color="orange.brandy"
        >
          {text}
        </Typography>
      );
    },
    toComplete: () => {
      return (
        <Box>
          <Box mb={Spacing.l}>
            <Typography variant="h6" weight={FontWeight.Semibold}>
              {needToComplete}
            </Typography>
          </Box>

          {completedFields.map((field) => (
            <Box key={field} mb={Spacing.s} display="flex" alignItems="center">
              <img src={checked} alt="checked" className={classes.icon} />
              <Typography
                variant={matches ? "caption" : "body1"}
                weight={FontWeight.Regular}
                color="primary"
              >
                <FormattedMessage id={COMPLETED_FIELDS.get(field as UserFields)} />
              </Typography>
            </Box>
          ))}

          {incompleteFieldsOnUserProfile(me).map((field) => (
            <Box key={field} mb={Spacing.s} display="flex" alignItems="center">
              <img src={exclamation} alt="exclamation-mark" className={classes.icon} />
              <Typography
                variant={matches ? "caption" : "body1"}
                weight={FontWeight.Regular}
                color="red.grenadier"
              >
                <FormattedMessage id={MISSING_FIELDS.get(field as UserFields)} />
              </Typography>
            </Box>
          ))}
        </Box>
      );
    },
  };

  const [title, description, update, later, needToComplete] = useFormatMessages([
    {
      id: "NOTIFICATION_COMPLETED_PROFILE",
      values: { percentage: me.completedPercentage, o: renderers.orange },
    },
    { id: "NOTIFICATION_COMPLETED_PROFILE_DESCRIPTION" },
    { id: "NOTIFICATION_UPDATE_PROFILE_NOW" },
    { id: "NOTIFICATION_MAYBE_LATER" },
    { id: "NOTIFICATION_NEED_TO_COMPLETE_PROFILE" },
  ]);

  return (
    <Modal open={isOpen} onClose={closeModal} noPadding fullSize>
      <Box className={classes.root}>
        <Box className={classes.container}>
          <Box mb={Spacing.l}>
            <UserBadge size="md" src={PerfectProfile} progress={70} />
          </Box>
          <Typography variant="h3" align="center" weight={FontWeight.Semibold}>
            {title}
          </Typography>
          <Box my={Spacing.l}>
            <Typography variant="body2" align="center">
              {description}
            </Typography>
          </Box>

          {matches ? <Box my={Spacing.l}>{renderers.toComplete()}</Box> : null}

          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Link to="/account/profile">
              <Button variant="contained" color="primary" onClick={closeModal}>
                {update}
              </Button>
            </Link>
            <Box mt={Spacing.m}>
              <Button variant="text" color="secondary" onClick={closeModal}>
                {later}
              </Button>
            </Box>
          </Box>
        </Box>

        {matches ? null : <Box>{renderers.toComplete()}</Box>}
      </Box>
    </Modal>
  );
}
