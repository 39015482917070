import { gql } from "@apollo/client";

export const CREATE_EVENT = gql`
  mutation createEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
    }
  }
`;

export const PUBLISH_EVENT = gql`
  mutation publishEvent($id: String!) {
    publishEvent(id: $id) {
      id
    }
  }
`;

export const DUPLICATE_EVENT = gql`
  mutation duplicateEvent($id: String!) {
    duplicateEvent(id: $id) {
      id
    }
  }
`;

export const CREATE_INVITE = gql`
  mutation createInvite($input: CreateInviteInput!) {
    createInvite(input: $input) {
      id
      status
      isHost
      user {
        id
        name
        avatarUrl
      }
    }
  }
`;

export const DELETE_INVITE = gql`
  mutation deleteInvite($id: String!) {
    deleteInvite(inviteId: $id) {
      status
    }
  }
`;

export const GENERATE_ATTACHMENT_UPLOAD_URL = gql`
  mutation generateAttachmentUploadUrl($filename: String!, $eventID: String!) {
    generateAttachmentUploadUrl(filename: $filename, eventID: $eventID) {
      contentURL
      uploadURL
    }
  }
`;

export const CREATE_EVENT_ATTACHMENT = gql`
  mutation createAttachment($eventID: String!, $input: CreateEventAttachmentInput!) {
    createAttachment(eventID: $eventID, input: $input) {
      id
      url
      meta {
        name
        extension
        size
      }
    }
  }
`;

export const DELETE_EVENT_ATTACHMENT = gql`
  mutation deleteAttachment($attachmentID: String!) {
    deleteAttachment(attachmentID: $attachmentID) {
      id
    }
  }
`;

export const DELETE_EVENT = gql`
  mutation deleteEvent($id: String!) {
    deleteEvent(id: $id) {
      status
    }
  }
`;

export const ATTEND_EVENT = gql`
  mutation attendEvent($input: AttendEventInput!) {
    attendEvent(input: $input) {
      id
      status
    }
  }
`;

export const CANCEL_ATTENDANCE = gql`
  mutation cancelAttendance($input: CancelAttendanceInput!) {
    cancelAttendance(input: $input) {
      id
      status
    }
  }
`;

export const DECLINE_INVITE = gql`
  mutation declineInvite($input: DeclineInviteInput!) {
    declineInvite(input: $input) {
      id
      status
    }
  }
`;

export const UPDATE_EVENT = gql`
  mutation updateEvent($id: String!, $input: UpdateEventInput!) {
    updateEvent(id: $id, input: $input) {
      title
      description
      kind
      conversationKind
      startDate
      endDate
      host {
        id
        firstName
        lastName
        avatarUrl
      }
      topic {
        id
      }
      organizer {
        id
        firstName
        lastName
        avatarUrl
      }
      isPublic
    }
  }
`;

export const CLOSE_DOOR = gql`
  mutation closeDoor($id: String!) {
    closeDoor(id: $id) {
      id
      closedDoorAt
    }
  }
`;

export const OPEN_DOOR = gql`
  mutation openDoor($id: String!) {
    openDoor(id: $id) {
      id
      closedDoorAt
    }
  }
`;

export const ADD_EVENT_TO_CALENDAR = gql`
  mutation addEventToGoogleCalendar($input: AddEventCalendarInput!) {
    addEventToGoogleCalendar(input: $input) {
      status
      url
      reason
    }
  }
`;

export const EVENT_MEETING_TOKEN = gql`
  mutation eventMeetingToken($input: DailyMeetingInput!) {
    eventMeetingToken(input: $input) {
      token
      exp
    }
  }
`;

export const MARK_JOIN_EVENT = gql`
  mutation markJoinEvent($eventId: String!) {
    markJoinEvent(eventId: $eventId) {
      status
    }
  }
`;

export const SAVE_PARTICIPANT_SESSION = gql`
  mutation saveParticipantSession($participantId: String!, $meetingSessionId: String!) {
    saveParticipantSession(participantId: $participantId, meetingSessionId: $meetingSessionId) {
      status
    }
  }
`;

export const UPLOAD_EVENT_DESCRIPTION_IMAGE = gql`
  mutation uploadEventDescriptionImage($input: UploadEventImageInput!) {
    uploadEventDescriptionImage(input: $input) {
      contentURL
    }
  }
`;

export const DELETE_INVITES = gql`
  mutation deleteInvites($input: DeleteInvitesInput!) {
    deleteInvites(input: $input) {
      status
    }
  }
`;

export const CONFIRM_IN_PERSON_EVENT = gql`
  mutation confirmInPersonEvent($eventId: String!, $userIds: [String!]!) {
    confirmInPersonEvent(eventId: $eventId, userIds: $userIds) {
      status
    }
  }
`;

export const SHARE_EVENT = gql`
  mutation shareEvent($eventId: String!, $userIds: [String!]!) {
    shareEvent(eventId: $eventId, userIds: $userIds) {
      status
    }
  }
`;
