import { Box, type Theme, useTheme } from "@mui/material";
import Confetti from "react-confetti";
import { FormattedMessage } from "react-intl";
import { useMeasure } from "react-use";
import { makeStyles } from "tss-react/mui";

import { Spacing } from "@hey-lady/shared/helpers/enum";
import { useFormatMessages, useSwitch } from "@hey-lady/shared/hooks/general";
import { useAuth } from "@hey-lady/shared/hooks/user";
import {
  type BadgeNotificationModel,
  type NotificationBaseModel,
  UserBadgeType,
} from "@hey-lady/shared/types/graphql";

import Modal from "@hey-lady/ui/atoms/Modal";
import StyledLink from "@hey-lady/ui/atoms/StyledLink";
import Typography from "@hey-lady/ui/atoms/Typography";

import { useNotifications } from "@hey-lady/app/context/NotificationsContext";
import UserBadge from "../../Badges/UserBadge";
import {
  ACHIEVEMENT_DESCRIPTIONS,
  ACHIEVEMENT_TITLES,
  BADGES_URLS,
  BADGE_TITLES_WITH_NAME,
} from "../../Badges/constants";
import BadgeNotificationModalActions from "./BadgeNotificationModalActions";

/**
 * Types
 */
interface Props {
  badgeNotification: BadgeNotificationModel;
  notificationId: NotificationBaseModel["id"];
}

/**
 * Consts
 */
const legendaryLadyLink =
  "https://app.heylady.io/topic/a7df7c48-19e6-473f-af86-18cd2003980c/overview";
const linkedinLink = "https://www.linkedin.com/";

/**
 * Styles
 */
const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: "560px",
    },
  },
  image: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minHeight: "200px",
    flexDirection: "column",
    paddingTop: theme.spacing(Spacing.xxl),
  },
  confetti: {
    position: "absolute",
    width: "100%",
    top: 0,
  },
  backHomeButton: {
    marginTop: theme.spacing(Spacing.m),
    "& .MuiButton-startIcon": {
      marginRight: theme.spacing(Spacing.xs),
    },
  },
  underline: {
    textDecoration: "underline",
  },
}));

export default function BadgeNotificationModal({ badgeNotification, notificationId }: Props) {
  const [isOpen, toggle] = useSwitch(true);
  const { markAsSeen } = useNotifications();

  const { classes } = useStyles();
  const theme = useTheme();

  const { me, refetch } = useAuth();

  const [ref, { width: parentWidth, height: parentHeight }] = useMeasure();

  const badgeType = badgeNotification.badge.type;

  const shouldContainName = BADGE_TITLES_WITH_NAME.includes(badgeType);

  const [legendaryLadyDescription, heyLadyWay, certifiedHostDescription] = useFormatMessages([
    {
      id: "BADGE_DESCRIPTION_LEGENDARY_LADY",
      values: {
        link: (link: string) => (
          <StyledLink href={legendaryLadyLink} variant="body2" align="center">
            {link}
          </StyledLink>
        ),
      },
    },
    {
      id: "BADGE_NEW_ONBOARDING_QUIZ_TITLE",
      values: { em: (str) => <em>{str}</em> },
    },
    {
      id: "BADGE_NEW_CERTIFIED_HOST",
      values: {
        mb: <br style={{ marginBottom: theme.spacing(Spacing.xs) }} />,
        link: (link: string) => (
          <StyledLink href={linkedinLink} variant="body2" align="center">
            {link}
          </StyledLink>
        ),
      },
    },
  ]);

  const closeModal = () => {
    markAsSeen(notificationId);
    void refetch();
    toggle();
  };

  return (
    <Modal open={isOpen} onClose={closeModal} noPadding>
      <Box ref={ref}>
        <Box display="flex" flexDirection="column" alignItems="center" className={classes.root}>
          <Box className={classes.image}>
            <Box className={classes.confetti}>
              <Confetti
                height={parentHeight}
                width={parentWidth}
                recycle={false}
                numberOfPieces={800}
                tweenDuration={10000}
                colors={[
                  theme.palette.green.eden,
                  theme.palette.green.jet,
                  theme.palette.orange.brandy,
                  theme.palette.orange.tumbleweed,
                  theme.palette.pink.azaela,
                  theme.palette.pink.peep,
                  theme.palette.gray.default,
                ]}
              />
            </Box>

            <UserBadge size="lg" src={BADGES_URLS.get(badgeType)} achieved />

            <Box my={Spacing.m} px={Spacing.xxl}>
              <Typography variant="h4" align="center">
                {badgeType === UserBadgeType.HeyLadyWay ? (
                  heyLadyWay
                ) : (
                  <FormattedMessage
                    id={ACHIEVEMENT_TITLES.get(badgeType)}
                    values={shouldContainName ? { name: me?.firstName } : undefined}
                  />
                )}
              </Typography>
            </Box>
          </Box>
          <Box px={Spacing.xl}>
            <Typography variant="body2" align="center" color="gray.default">
              {badgeType === UserBadgeType.LegendaryLady ? (
                legendaryLadyDescription
              ) : badgeType === UserBadgeType.CertifiedHost ? (
                certifiedHostDescription
              ) : (
                <FormattedMessage
                  id={ACHIEVEMENT_DESCRIPTIONS.get(badgeType)}
                  values={{ br: <br style={{ marginBottom: theme.spacing(Spacing.s) }} /> }}
                />
              )}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            my={Spacing.xxl}
          >
            <BadgeNotificationModalActions badgeType={badgeType} handleClick={closeModal} />
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
