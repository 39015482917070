import { type ApolloError } from "@apollo/client";
import Alert from "@hey-lady/ui/molecules/Alert";
import Box, { type BoxProps } from "@mui/material/Box";
import { isNil } from "ramda";

/**
 * Types
 */
interface Props extends BoxProps {
  error?: ApolloError | Error | null;
}

const ErrorAlert: React.FC<Props> = ({ error, ...props }: Props) => {
  if (isNil(error)) {
    return null;
  }
  return (
    <Box {...props}>
      <Alert severity="error">{error.message}</Alert>
    </Box>
  );
};

export default ErrorAlert;
