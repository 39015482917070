import { useNotifications } from "@hey-lady/app/context/NotificationsContext";
import NotificationModalMatcher from "./NotificationModalMatcher";
import { isUnreadNotification } from "./utils";

export default function NotificationsModalDisplay() {
  const { popupNotifications } = useNotifications();

  return popupNotifications
    .filter(isUnreadNotification)
    .map((notification) => (
      <NotificationModalMatcher key={notification.id} notification={notification} />
    ));
}
