import { gql } from "@apollo/client";
import {
  ARTICLE_NOTIFICATION_FIELDS,
  BADGE_NOTIFICATION_FIELDS,
  CHAT_MENTION_NOTIFICATION_FIELDS,
  COMMENT_NOTIFICATION_FIELDS,
  EVENT_NOTIFICATION_FIELDS,
  FRIEND_JOINED_NOTIFICATION_FIELDS,
  MONTHLY_REPORT_NOTIFICATION_FIELDS,
  PROFILE_UPDATE_NOTIFICATION_FIELDS,
} from "../fragments/notifications";

export const NOTIFICATIONS_SUBSCRIPTION = gql`
  ${COMMENT_NOTIFICATION_FIELDS}
  ${EVENT_NOTIFICATION_FIELDS}
  ${CHAT_MENTION_NOTIFICATION_FIELDS}
  ${ARTICLE_NOTIFICATION_FIELDS}
  subscription notificationsSubscription {
    notificationAdded {
      id
      type
      createdAt
      notificationSeen {
        id
        seenAt
      }
      chatMentionNotification {
        ...ChatMentionNotificationFields
      }
      commentNotification {
        ...CommentNotificationFields
      }
      eventNotification {
        ...EventNotificationFields
      }
      articleNotification {
        ...ArticleNotificationFields
      }
    }
  }
`;

export const POPUP_NOTIFICATIONS_SUBSCRIPTION = gql`
  ${EVENT_NOTIFICATION_FIELDS}
  ${BADGE_NOTIFICATION_FIELDS}
  ${PROFILE_UPDATE_NOTIFICATION_FIELDS}
  ${MONTHLY_REPORT_NOTIFICATION_FIELDS}
  ${FRIEND_JOINED_NOTIFICATION_FIELDS}
  subscription popupNotificationsSubscription {
    popupNotificationAdded {
      id
      type
      createdAt
      notificationSeen {
        id
        seenAt
      }
      badgeNotification {
        ...BadgeNotificationFields
      }
      eventNotification {
        ...EventNotificationFields
      }
      profileUpdateNotification {
        ...ProfileUpdateNotificationFields
      }
      monthlyReportNotification {
        ...MonthlyReportNotificationFields
      }
      friendJoinedNotification {
        ...FriendJoinedNotificationFields
      }
    }
  }
`;
