import { IntlShape } from "react-intl";

import {
  AppliedFilterType,
  DEFAULT_EVENTS_FILTERS,
  DEFAULT_PEOPLE_FILTERS,
  EVENT_DURATION_FILTER,
  EventFilters,
  EventsFilterCategory,
  Filters,
  OCCUPATION_FILTER,
  UrlFilterParams,
} from "./consts";

export const getUpdatedEventFilters = (
  filters: EventFilters["events"],
  filter: AppliedFilterType,
) => {
  const filterValue = filters[filter.category as EventsFilterCategory];
  if (Array.isArray(filterValue)) {
    return filterValue.filter((v: any) => {
      if (filter.category === OCCUPATION_FILTER) {
        return v.id !== filter.value;
      }
      if (filter.category === EVENT_DURATION_FILTER) {
        return Number(v) !== Number(filter.value);
      }
      return v !== filter.value;
    });
  }
  return DEFAULT_EVENTS_FILTERS.events[filter.category as EventsFilterCategory];
};

export const translateEventDurationFilter = (duration: number, intl: IntlShape) => {
  return {
    id: duration,
    title: intl.formatMessage({ id: `ENUM_EVENT_DURATION_FILTER_${duration}` }),
  };
};

export const getAppliedEventFiltersNumber = (filters: EventFilters["events"]) => {
  const appliedFilters = Object.entries(filters).filter(([key, value]) => {
    const filterKey = key as keyof EventFilters["events"];
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return filters[filterKey] !== DEFAULT_EVENTS_FILTERS.events[filterKey];
  });
  return appliedFilters.length;
};

export const getAppliedPeopleFiltersNumber = (filters: Filters["people"]) => {
  const appliedFilters = Object.entries(filters).filter(([key, value]) => {
    const filterKey = key as keyof Filters["people"];
    if (Array.isArray(value) && key !== "availability") {
      return value.length > 0;
    }
    if (key === "availability") {
      return filters[filterKey][0] !== DEFAULT_PEOPLE_FILTERS.people[filterKey][0];
    }
    return filters[filterKey] !== DEFAULT_PEOPLE_FILTERS.people[filterKey];
  });
  return appliedFilters.length;
};

export const getEventFiltersSearchParams = (filters: EventFilters) => {
  const newQuery = new URLSearchParams();
  newQuery.set(UrlFilterParams.Availability, filters.events.availability);
  newQuery.set(UrlFilterParams.Host, filters.events.host);
  newQuery.set(UrlFilterParams.Type, filters.events.type);
  newQuery.set(UrlFilterParams.EnglishLevel, filters.events.englishLevel);
  filters.events.eventDate && newQuery.set(UrlFilterParams.EventDate, filters.events.eventDate);
  newQuery.set(UrlFilterParams.Full, String(filters.events.showFullEvents));
  filters.events.topicId && newQuery.set(UrlFilterParams.TopicId, filters.events.topicId);
  newQuery.set(UrlFilterParams.Interests, filters.events.interests?.join(",") || "");
  newQuery.set(UrlFilterParams.EnglishSkills, filters.events.englishSkills?.join(",") || "");
  newQuery.set(UrlFilterParams.Occupations, filters.events.occupations?.join(",") || "");
  newQuery.set(UrlFilterParams.Duration, filters.events.duration?.join(",") || "");
  return newQuery.toString();
};
