import {
  Box,
  Fade,
  IconButton,
  type ModalProps,
  Modal as MuiModal,
  type Theme,
  useTheme,
} from "@mui/material";
import React from "react";
import { makeStyles } from "tss-react/mui";

import { Spacing } from "@hey-lady/shared/helpers/enum";

import IconCross from "@hey-lady/ui/icons/IconCross";
import config from "../overrides/config";

/**
 * Types
 */

interface Props extends ModalProps {
  variant?: "light" | "beige";
  onClose: () => void;
  fullSize?: boolean;
  hideButton?: boolean;
  noPadding?: boolean;
}

/**
 * Styles
 */
const useStyles = makeStyles<{ params: Partial<Props> }>()((theme: Theme, { params }) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    minWidth: "480px",
    maxHeight: "100vh",
    overflowY: "auto",
    overflowX: "hidden",
    position: "relative",
    padding: params.noPadding ? "unset" : theme.spacing(Spacing.xxl),
    borderRadius: config.radius.sm,
    [theme.breakpoints.down("md")]: {
      padding: params.noPadding ? "unset" : theme.spacing(Spacing.xl),
    },
    [theme.breakpoints.down("sm")]: {
      width: params.fullSize ? "100%" : "90%",
      minWidth: "auto",
      padding: params.noPadding ? "unset" : theme.spacing(Spacing.l),
      borderRadius: params.fullSize ? "unset" : config.radius.sm,
      height: params.fullSize ? "100%" : "unset",
      overflowY: params.fullSize ? "auto" : "unset",
    },
  },
  button: {
    zIndex: 999,
    padding: "unset",
    position: "absolute",
    top: theme.spacing(Spacing.xxl),
    right: theme.spacing(Spacing.xxl),
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(Spacing.l),
      right: theme.spacing(Spacing.l),
    },
  },
  light: {
    backgroundColor: theme.palette.white,
  },
  beige: {
    backgroundColor: theme.palette.beige.bianca,
  },
}));

const Modal: React.FC<Props> = ({
  open,
  children,
  variant = "light",
  onClose,
  fullSize,
  hideButton,
  noPadding,
  ...props
}: Props) => {
  const theme = useTheme();
  const params = { fullSize, noPadding };
  const { classes, cx } = useStyles({ params });

  return (
    <MuiModal open={open} className={classes.root} onClose={onClose} {...props}>
      <Fade in={open}>
        <Box
          className={cx(classes.box, {
            [classes.light]: variant === "light",
            [classes.beige]: variant === "beige",
          })}
        >
          {hideButton ? null : (
            <IconButton className={classes.button} onClick={onClose} disableRipple>
              <IconCross htmlColor={theme.palette.gray.default} />
            </IconButton>
          )}

          {children}
        </Box>
      </Fade>
    </MuiModal>
  );
};

export default Modal;
