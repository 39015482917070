import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { inc, isNil, slice } from "ramda";
import React, { type ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { HOURS } from "@hey-lady/shared/helpers/const";

import { FontWeight, Spacing } from "@hey-lady/shared/helpers/enum";

import Typography from "@hey-lady/ui/atoms/Typography";

/**
 * Types
 */
interface Props {
  from?: string | null;
  until?: string | null;
  title: string | ReactNode;
  onChange: (name: string, value: unknown) => void;
}

const HoursGroup: React.FC<Props> = ({ title, from, until, onChange, ...props }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const renderers = {
    options: (value?: string | null) => {
      if (isNil(value)) {
        return null;
      }

      const start = inc(HOURS.indexOf(value));
      const hours = slice(start, Number.POSITIVE_INFINITY, HOURS);

      return hours.map((hour) => (
        <MenuItem value={hour} key={hour}>
          {hour}
        </MenuItem>
      ));
    },
  };

  return (
    <Box mb={Spacing.xl} {...props}>
      <Box mb={Spacing.ml}>
        <Typography variant="h6" weight={FontWeight.Regular}>
          {title}
        </Typography>
      </Box>

      <Grid wrap="nowrap" spacing={matches ? Spacing.m : Spacing.l} container>
        <Grid xs item>
          <FormControl>
            <InputLabel>
              <FormattedMessage id="COMMON_FROM" />
            </InputLabel>
            <Select
              variant="standard"
              name="from"
              data-test-id="from"
              value={from}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
              }}
            >
              {HOURS.map((hour) => (
                <MenuItem value={hour} key={hour}>
                  {hour}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid xs item>
          <FormControl>
            <InputLabel>
              <FormattedMessage id="COMMON_UNTIL" />
            </InputLabel>
            <Select
              variant="standard"
              name="until"
              data-test-id="until"
              value={until}
              disabled={isNil(from)}
              onChange={(event) => {
                onChange(event.target.name, event.target.value);
              }}
            >
              {renderers.options(from)}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HoursGroup;
