import React from "react";

import SvgIcon, { type Props } from "@hey-lady/ui/atoms/SvgIcon";

const IconEvents: React.FC<Props> = (props: Props) => {
  return (
    <SvgIcon {...props}>
      <path d="M12 24C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zm0-22C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z" />
      <path d="M12 19c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1zM12 15.5c-.6 0-1-.4-1-1 0-1.4.8-2.4 2.4-3.7.4-.4 1.8-1.7 1-3-.5-.9-2.5-1-4.2-.3-.4.2-1 0-1.3-.5-.2-.5 0-1.1.5-1.3 2.3-1 5.5-.9 6.7 1 1.1 1.8.6 3.9-1.4 5.6-1.5 1.2-1.7 1.7-1.7 2.2 0 .6-.4 1-1 1z" />
    </SvgIcon>
  );
};

export default IconEvents;
