import { UserBadgeType } from "@hey-lady/shared/types/graphql";

import Events10 from "@hey-lady/shared/static/images/badges/10Events.svg";
import Events100 from "@hey-lady/shared/static/images/badges/100Events.svg";
import Events500 from "@hey-lady/shared/static/images/badges/500Events.svg";
import Ambassador from "@hey-lady/shared/static/images/badges/Ambassador.svg";
import Author from "@hey-lady/shared/static/images/badges/Author.svg";
import BigSister from "@hey-lady/shared/static/images/badges/BigSister.svg";
import BroughtAFriend from "@hey-lady/shared/static/images/badges/BroughtAFriend.svg";
import CertifiedHost from "@hey-lady/shared/static/images/badges/CertifiedHost.svg";
import PerfectProfile from "@hey-lady/shared/static/images/badges/CompleteProfile.svg";
import ConversationQueen from "@hey-lady/shared/static/images/badges/ConversationQueen.svg";
import DiamondAmbassador from "@hey-lady/shared/static/images/badges/DiamondAmbassador.svg";
import EventHost from "@hey-lady/shared/static/images/badges/EventHost.svg";
import FoundingMember from "@hey-lady/shared/static/images/badges/FoundingMember.svg";
import HeyLadyWay from "@hey-lady/shared/static/images/badges/HeyLadyWay.svg";
import Hosted10Events from "@hey-lady/shared/static/images/badges/Hosted10Events.svg";
import Hosted50Events from "@hey-lady/shared/static/images/badges/Hosted50Events.svg";
import LegendaryLady from "@hey-lady/shared/static/images/badges/LegendaryLady.svg";
import Membership1 from "@hey-lady/shared/static/images/badges/OneYearAnniversary.svg";
import PlatinumAmbassador from "@hey-lady/shared/static/images/badges/PlatinumAmbassador.svg";
import SuperHost100 from "@hey-lady/shared/static/images/badges/SuperHost100.svg";
import Membership3 from "@hey-lady/shared/static/images/badges/ThreeYearAnniversary.svg";
import Membership2 from "@hey-lady/shared/static/images/badges/TwoYearAnniversary.svg";
import UltraHost500 from "@hey-lady/shared/static/images/badges/UltraHost500.svg";
import Verified from "@hey-lady/shared/static/images/badges/Verified.svg";

export const BADGES_URLS = new Map<UserBadgeType, string>([
  [UserBadgeType.ArticlesPublished_1, Author],
  [UserBadgeType.BigSister, BigSister],
  [UserBadgeType.EventsParticipated_10, Events10],
  [UserBadgeType.EventsParticipated_100, Events100],
  [UserBadgeType.EventsParticipated_500, Events500],
  [UserBadgeType.EventsParticipated_1000, ConversationQueen],
  [UserBadgeType.FoundingMember, FoundingMember],
  [UserBadgeType.Hosted_1, EventHost],
  [UserBadgeType.Hosted_10, Hosted10Events],
  [UserBadgeType.Hosted_50, Hosted50Events],
  [UserBadgeType.Hosted_100, SuperHost100],
  [UserBadgeType.Hosted_500, UltraHost500],
  [UserBadgeType.LegendaryLady, LegendaryLady],
  [UserBadgeType.Verified, Verified],
  [UserBadgeType.PerfectProfile, PerfectProfile],
  [UserBadgeType.Membership_1, Membership1],
  [UserBadgeType.Membership_2, Membership2],
  [UserBadgeType.Membership_3, Membership3],
  [UserBadgeType.HeyLadyWay, HeyLadyWay],
  [UserBadgeType.BroughtAFriend, BroughtAFriend],
  [UserBadgeType.Ambassador, Ambassador],
  [UserBadgeType.PlatinumAmbassador, PlatinumAmbassador],
  [UserBadgeType.DiamondAmbassador, DiamondAmbassador],
  [UserBadgeType.CertifiedHost, CertifiedHost],
]);

export const BADGES_TITLES = new Map<UserBadgeType, string>([
  [UserBadgeType.ArticlesPublished_1, "BADGE_TITLE_AUTHOR"],
  [UserBadgeType.BigSister, "BADGE_TITLE_BIG_SISTER"],
  [UserBadgeType.EventsParticipated_10, "BADGE_TITLE_10_EVENTS"],
  [UserBadgeType.EventsParticipated_100, "BADGE_TITLE_100_EVENTS"],
  [UserBadgeType.EventsParticipated_500, "BADGE_TITLE_500_EVENTS"],
  [UserBadgeType.EventsParticipated_1000, "BADGE_TITLE_CONVERSATION_QUEEN"],
  [UserBadgeType.FoundingMember, "BADGE_TITLE_FOUNDING_MEMBER"],
  [UserBadgeType.Hosted_1, "BADGE_TITLE_EVENT_HOST"],
  [UserBadgeType.Hosted_10, "BADGE_TITLE_HOSTED_10_EVENTS"],
  [UserBadgeType.Hosted_50, "BADGE_TITLE_HOSTED_50_EVENTS"],
  [UserBadgeType.Hosted_100, "BADGE_TITLE_SUPER_HOST"],
  [UserBadgeType.Hosted_500, "BADGE_TITLE_ULTRA_HOST"],
  [UserBadgeType.LegendaryLady, "BADGE_TITLE_LEGENDARY_LADY"],
  [UserBadgeType.Verified, "BADGE_TITLE_VERIFIED"],
  [UserBadgeType.PerfectProfile, "BADGE_TITLE_PERFECT_PROFILE"],
  [UserBadgeType.Membership_1, "BADGE_TITLE_ONE_YEAR_ANNIVERSARY"],
  [UserBadgeType.Membership_2, "BADGE_TITLE_TWO_YEAR_ANNIVERSARY"],
  [UserBadgeType.Membership_3, "BADGE_TITLE_THREE_YEAR_ANNIVERSARY"],
  [UserBadgeType.HeyLadyWay, "BADGE_TITLE_ONBOARDING_QUIZ"],
  [UserBadgeType.BroughtAFriend, "BADGE_TITLE_BROUGHT_A_FRIEND"],
  [UserBadgeType.Ambassador, "BADGE_TITLE_AMBASSADOR"],
  [UserBadgeType.PlatinumAmbassador, "BADGE_TITLE_PLATINUM_AMBASSADOR"],
  [UserBadgeType.DiamondAmbassador, "BADGE_TITLE_DIAMOND_AMBASSADOR"],
  [UserBadgeType.CertifiedHost, "BADGE_TITLE_CERTIFIED_HOST"],
]);

export const BADGES_DESCRIPTIONS = new Map<UserBadgeType, string>([
  [UserBadgeType.ArticlesPublished_1, "BADGE_DESCRIPTION_AUTHOR"],
  [UserBadgeType.BigSister, "BADGE_DESCRIPTION_BIG_SISTER"],
  [UserBadgeType.EventsParticipated_10, "BADGE_DESCRIPTION_10_EVENTS"],
  [UserBadgeType.EventsParticipated_100, "BADGE_DESCRIPTION_100_EVENTS"],
  [UserBadgeType.EventsParticipated_500, "BADGE_DESCRIPTION_500_EVENTS"],
  [UserBadgeType.EventsParticipated_1000, "BADGE_DESCRIPTION_CONVERSATION_QUEEN"],
  [UserBadgeType.FoundingMember, "BADGE_DESCRIPTION_FOUNDING_MEMBER"],
  [UserBadgeType.Hosted_1, "BADGE_DESCRIPTION_EVENT_HOST"],
  [UserBadgeType.Hosted_10, "BADGE_DESCRIPTION_HOSTED_10_EVENTS"],
  [UserBadgeType.Hosted_50, "BADGE_DESCRIPTION_HOSTED_50_EVENTS"],
  [UserBadgeType.Hosted_100, "BADGE_DESCRIPTION_SUPER_HOST"],
  [UserBadgeType.Hosted_500, "BADGE_DESCRIPTION_ULTRA_HOST"],
  [UserBadgeType.LegendaryLady, "BADGE_DESCRIPTION_LEGENDARY_LADY"],
  [UserBadgeType.Verified, "BADGE_DESCRIPTION_VERIFIED"],
  [UserBadgeType.PerfectProfile, "BADGE_DESCRIPTION_PERFECT_PROFILE"],
  [UserBadgeType.Membership_1, "BADGE_DESCRIPTION_ONE_YEAR_ANNIVERSARY"],
  [UserBadgeType.Membership_2, "BADGE_DESCRIPTION_TWO_YEAR_ANNIVERSARY"],
  [UserBadgeType.Membership_3, "BADGE_DESCRIPTION_THREE_YEAR_ANNIVERSARY"],
  [UserBadgeType.HeyLadyWay, "BADGE_DESCRIPTION_ONBOARDING_QUIZ"],
  [UserBadgeType.BroughtAFriend, "BADGE_DESCRIPTION_BROUGHT_A_FRIEND"],
  [UserBadgeType.Ambassador, "BADGE_DESCRIPTION_AMBASSADOR"],
  [UserBadgeType.PlatinumAmbassador, "BADGE_DESCRIPTION_PLATINUM_AMBASSADOR"],
  [UserBadgeType.DiamondAmbassador, "BADGE_DESCRIPTION_DIAMOND_AMBASSADOR"],
  [UserBadgeType.CertifiedHost, "BADGE_DESCRIPTION_CERTIFIED_HOST"],
]);

export const ACHIEVEMENT_TITLES = new Map<UserBadgeType, string>([
  [UserBadgeType.ArticlesPublished_1, "BADGE_NEW_AUTHOR_TITLE"],
  [UserBadgeType.BigSister, "BADGE_NEW_BIG_SISTER_TITLE"],
  [UserBadgeType.EventsParticipated_10, "BADGE_NEW_10_EVENTS_TITLE"],
  [UserBadgeType.EventsParticipated_100, "BADGE_NEW_100_EVENTS_TITLE"],
  [UserBadgeType.EventsParticipated_500, "BADGE_NEW_500_EVENTS_TITLE"],
  [UserBadgeType.EventsParticipated_1000, "BADGE_NEW_CONVERSATION_QUEEN_TITLE"],
  [UserBadgeType.FoundingMember, "BADGE_NEW_FOUNDING_MEMBER_TITLE"],
  [UserBadgeType.Hosted_1, "BADGE_NEW_EVENT_HOST_TITLE"],
  [UserBadgeType.Hosted_10, "BADGE_NEW_HOSTED_10_EVENTS_TITLE"],
  [UserBadgeType.Hosted_50, "BADGE_NEW_HOSTED_50_EVENTS_TITLE"],
  [UserBadgeType.Hosted_100, "BADGE_NEW_SUPER_HOST_TITLE"],
  [UserBadgeType.Hosted_500, "BADGE_NEW_ULTRA_HOST_TITLE"],
  [UserBadgeType.LegendaryLady, "BADGE_NEW_LEGENDARY_LADY_TITLE"],
  [UserBadgeType.Verified, "BADGE_NEW_VERIFIED_TITLE"],
  [UserBadgeType.PerfectProfile, "BADGE_NEW_PERFECT_PROFILE_TITLE"],
  [UserBadgeType.Membership_1, "BADGE_NEW_ONE_YEAR_ANNIVERSARY_TITLE"],
  [UserBadgeType.Membership_2, "BADGE_NEW_TWO_YEAR_ANNIVERSARY_TITLE"],
  [UserBadgeType.Membership_3, "BADGE_NEW_THREE_YEAR_ANNIVERSARY_TITLE"],
  [UserBadgeType.HeyLadyWay, "BADGE_NEW_ONBOARDING_QUIZ_TITLE"],
  [UserBadgeType.BroughtAFriend, "BADGE_NEW_BROUGHT_A_FRIEND_TITLE"],
  [UserBadgeType.Ambassador, "BADGE_NEW_AMBASSADOR_TITLE"],
  [UserBadgeType.PlatinumAmbassador, "BADGE_NEW_PLATINUM_AMBASSADOR_TITLE"],
  [UserBadgeType.DiamondAmbassador, "BADGE_NEW_DIAMOND_AMBASSADOR_TITLE"],
  [UserBadgeType.CertifiedHost, "BADGE_NEW_CERTIFIED_HOST_TITLE"],
]);

export const ACHIEVEMENT_DESCRIPTIONS = new Map<UserBadgeType, string>([
  [UserBadgeType.ArticlesPublished_1, "BADGE_NEW_AUTHOR"],
  [UserBadgeType.BigSister, "BADGE_NEW_BIG_SISTER"],
  [UserBadgeType.EventsParticipated_10, "BADGE_NEW_10_EVENTS"],
  [UserBadgeType.EventsParticipated_100, "BADGE_NEW_100_EVENTS"],
  [UserBadgeType.EventsParticipated_500, "BADGE_NEW_500_EVENTS"],
  [UserBadgeType.EventsParticipated_1000, "BADGE_NEW_CONVERSATION_QUEEN"],
  [UserBadgeType.FoundingMember, "BADGE_NEW_FOUNDING_MEMBER"],
  [UserBadgeType.Hosted_1, "BADGE_NEW_EVENT_HOST"],
  [UserBadgeType.Hosted_10, "BADGE_NEW_HOSTED_10_EVENTS"],
  [UserBadgeType.Hosted_50, "BADGE_NEW_HOSTED_50_EVENTS"],
  [UserBadgeType.Hosted_100, "BADGE_NEW_SUPER_HOST"],
  [UserBadgeType.Hosted_500, "BADGE_NEW_ULTRA_HOST"],
  [UserBadgeType.LegendaryLady, "BADGE_NEW_LEGENDARY_LADY"],
  [UserBadgeType.Verified, "BADGE_NEW_VERIFIED"],
  [UserBadgeType.PerfectProfile, "BADGE_NEW_PERFECT_PROFILE"],
  [UserBadgeType.Membership_1, "BADGE_NEW_ONE_YEAR_ANNIVERSARY"],
  [UserBadgeType.Membership_2, "BADGE_NEW_TWO_YEAR_ANNIVERSARY"],
  [UserBadgeType.Membership_3, "BADGE_NEW_THREE_YEAR_ANNIVERSARY"],
  [UserBadgeType.HeyLadyWay, "BADGE_NEW_ONBOARDING_QUIZ"],
  [UserBadgeType.BroughtAFriend, "BADGE_NEW_BROUGHT_A_FRIEND"],
  [UserBadgeType.Ambassador, "BADGE_NEW_AMBASSADOR"],
  [UserBadgeType.PlatinumAmbassador, "BADGE_NEW_PLATINUM_AMBASSADOR"],
  [UserBadgeType.DiamondAmbassador, "BADGE_NEW_DIAMOND_AMBASSADOR"],
  [UserBadgeType.CertifiedHost, "BADGE_NEW_CERTIFIED_HOST"],
]);

export const BADGES_WITH_PROGRESS = [
  UserBadgeType.PerfectProfile,
  UserBadgeType.EventsParticipated_10,
  UserBadgeType.EventsParticipated_100,
  UserBadgeType.EventsParticipated_500,
  UserBadgeType.EventsParticipated_1000,
  UserBadgeType.Hosted_10,
  UserBadgeType.Hosted_50,
  UserBadgeType.Hosted_100,
  UserBadgeType.Hosted_500,
  UserBadgeType.Ambassador,
  UserBadgeType.PlatinumAmbassador,
  UserBadgeType.DiamondAmbassador,
];

export const BADGE_TITLES_WITH_NAME = [
  UserBadgeType.Hosted_1,
  UserBadgeType.Hosted_10,
  UserBadgeType.Hosted_50,
  UserBadgeType.Hosted_100,
  UserBadgeType.Hosted_500,
  UserBadgeType.Verified,
  UserBadgeType.Membership_1,
  UserBadgeType.Membership_2,
  UserBadgeType.Membership_3,
];
