import {
  type CommentModel,
  CommentTargetType,
  EventNotificationAction,
  type NotificationBaseModel,
  NotificationType,
} from "@hey-lady/shared/types/graphql";
import { isEmpty } from "ramda";

export function getChatMentionLink(
  channelId: string,
  messageId?: string | null,
  parentId?: string | null,
): string {
  const messagePart = messageId ? `&commentId=${messageId}` : "";
  const parentLinkPart = parentId ? `&parentId=${parentId}` : "";
  return `/chat?channelId=${channelId}${messagePart}${parentLinkPart}`;
}

export function getTargetLink(comment: CommentModel) {
  if (comment.targetType === CommentTargetType.Article) {
    return `/memberzine/article/${comment.targetId}?commentId=${comment.id}`;
  }
  if (comment.targetType === CommentTargetType.Event) {
    return `/event/${comment.targetId}?commentId=${comment.id}`;
  }
  if (comment.targetType === CommentTargetType.Topic) {
    return `/topic/${comment.targetId}/overview?commentId=${comment.id}`;
  }
  return "";
}

export function getTargetTitle(comment: CommentModel) {
  if (comment.targetType === CommentTargetType.Article && comment.targetArticle) {
    return comment.targetArticle.title;
  }
  if (comment.targetType === CommentTargetType.Event && comment.targetEvent) {
    return comment.targetEvent.title;
  }
  if (comment.targetType === CommentTargetType.Topic && comment.targetTopic) {
    return comment.targetTopic.title;
  }
  return "";
}

export function isUnreadNotification(notification: NotificationBaseModel) {
  return isEmpty(notification.notificationSeen);
}

export function isPopupNotification(notification: NotificationBaseModel) {
  return (
    notification.type === NotificationType.Badge ||
    (notification.type === NotificationType.Event &&
      notification.eventNotification?.action === EventNotificationAction.InApp15MinBeforeEvent)
  );
}
