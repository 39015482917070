import {
  differenceInSeconds,
  endOfMonth,
  formatDuration,
  intervalToDuration,
  startOfMonth,
  subMonths,
} from "date-fns";

export const getHoursAndMinutesFromSecs = (timeInSec: number) => {
  const hours = timeInSec >= 3600 ? Math.floor(timeInSec / 3600) : 0;
  const minutes = Math.ceil((timeInSec % 3600) / 60);

  return { hours, minutes };
};

export const getHoursAndMinutesFromMins = (timeInMin: number) => {
  const hours = timeInMin >= 60 ? Math.floor(timeInMin / 60) : 0;
  const minutes = Math.ceil(timeInMin % 60);

  return { hours, minutes };
};

export const getLastMonthDates = (date: Date, amount: number) => {
  const month = subMonths(date, amount);
  const startDate = startOfMonth(month);
  const endDate = endOfMonth(month);

  return { month, startDate, endDate };
};

export const formatInTwoDigits = (value: number | undefined) => {
  return value?.toString().padStart(2, "0") || "00";
};

export const getTimeUntilEvent = (startDate: Date, now: Date) => {
  const seconds = differenceInSeconds(startDate, now);
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  return `${formatInTwoDigits(duration.hours)}:${formatInTwoDigits(duration.minutes)}:${formatInTwoDigits(
    duration.seconds,
  )}`;
};

export const getDuration = (startDate: Date, endDate: Date) => {
  return formatDuration(intervalToDuration({ start: startDate, end: endDate }));
};
